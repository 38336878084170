body {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.package-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-position: center center;
  background: linear-gradient(to top, rgb(91, 60, 139) 0%, rgb(0, 0, 0) 100%);

  .package-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .package-close {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
  .text {
    display: flex;
    justify-content: center;
  }
  .mini-text {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 768px) {
    .text {
      font-family: "Open Sans", sans-serif;
      font-size: 3vw;
      line-height: 5vw;
      color: white;
    }
    .mini-text {
      font-family: "Open Sans", sans-serif;
      font-size: 3vw;
      line-height: 4vw;
      color: white;
      margin-top: 15px;
    }
  }
  @media (min-width: 768px) {
    .text {
      font-family: "Open Sans", sans-serif;
      font-size: 1.82vw;
      line-height: 5vw;
      color: white;
    }
    .mini-text {
      font-family: "Open Sans", sans-serif;
      font-size: 1vw;
      line-height: 2vw;
      color: white;
    }
  }
  .package-item-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    min-height: 60vw;
    .slick-button.prev {
      cursor: pointer;
    }
    .slick-button.next {
      cursor: pointer;
    }
    .slick-slide {
      transition: all 0.5s ease;
      transform: translateY(0);
    }
  }
  .center {
    .slick-center {
      transform: translateY(-50px);
    }
  }
  .package-item {
    // border: 1px solid #b783ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 25px;
    margin-right: 25px;
    min-height: 50vw;
    width: 90%;
    background-color: #1b1629;
    border-radius: 21.11px;
    margin-top: 8vw;
    overflow: hidden;
    position: relative;
    .package-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
    }
    .package-name {
      font-family: "Open Sans", sans-serif;
      font-size: 0.8vw;
      line-height: 2vw;
      color: #e675f8;
    }
    .package-desc {
      height: 200px;
      padding-inline: 50px;
      font-family: "Open Sans", sans-serif;
      font-size: 0.4vw;
      line-height: 2vw;
      color: white;
      scrollbar-color: white transparent;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .package-desc::-webkit-scrollbar {
      width: 12px;
    }

    .package-desc::-webkit-scrollbar-track {
      background: transparent;
    }

    .package-desc::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 6px;
    }

    .package-button {
      margin-top: 30px;
      width: 160px;
      // background-color: #b783ff;
      background: linear-gradient(90deg, #ff0078, #8300e6);

      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: "Open Sans", sans-serif;
      font-size: 0.8vw;
      line-height: 2vw;
      color: black;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      .package-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
      }

      .package-name {
        font-family: "Open Sans", sans-serif;
        font-size: 20px; /* 2vw yerine sabit bir piksel değeri kullanıldı */
        line-height: 40px; /* 4vw yerine sabit bir piksel değeri kullanıldı */
        color: #e675f8;
      }

      .package-desc {
        margin-top: 30px;
        height: 200px;
        padding-inline: 20px; /* padding-inline azaltıldı */
        font-family: "Open Sans", sans-serif;
        font-size: 1.5vw; /* 2vw yerine sabit bir piksel değeri kullanıldı */
        line-height: 24px; /* 3vw yerine sabit bir piksel değeri kullanıldı */
        color: white;
        scrollbar-color: white transparent;
        overflow-y: auto; /* Dikey kaydırma izinli */
        overflow-x: hidden; /* Yatay kaydırma gizli */
      }

      .package-button {
        width: 220px;
        height: 40px;
        background-color: #b783ff;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: black;
        cursor: pointer;
      }

      .package-button-selected {
        margin-top: 30px;
        width: 220px;
        height: 40px;
        // background-color: #7820f3;
        background: linear-gradient(90deg, #ff0078, #8300e6);
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        line-height: 10.5vw;
        color: black;
        pointer-events: none;
      }

      .cancel-package-button {
        margin-top: 20px;
        width: 220px;
        height: 40px;
        background-color: #f07a22;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Open Sans", sans-serif;
        font-size: 3.8vw;
        line-height: 10.5vw;
        color: black;
        cursor: pointer;
      }
    }
    @media (min-width: 768px) {
      .package-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
      }

      .package-name {
        font-family: "Open Sans", sans-serif;
        font-size: 20px;
        line-height: 40px;
        color: #e675f8;
      }

      .package-desc {
        margin-top: 30px;
        height: 200px;
        padding-inline: 20px;
        font-family: "Open Sans", sans-serif;
        font-size: 1vw;
        line-height: 24px;
        color: white;
        scrollbar-color: white transparent;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .package-button {
        width: 220px;
        height: 40px;
        background-color: #b783ff;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: black;
        cursor: pointer;
      }

      // @keyframes border-gradient {
      //   0% {
      //     background: linear-gradient(90deg, #ff0078, #8300e6);
      //   }
      //   50% {
      //     background: linear-gradient(90deg, #8300e6, #ff0078);
      //   }
      //   100% {
      //     background: linear-gradient(90deg, #ff0078, #8300e6);
      //   }
      // }

      .package-button-cancel {
        margin-top: 10px;
        position: relative;
        width: 10vw;
        height: 3vw;
        background-color: #1b1629; /* Düğme iç rengi */
        color: white;
        border-radius: 22.88px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        cursor: pointer;
        z-index: 1;
      }

      .package-button-cancel:hover {
        background: linear-gradient(90deg, #ff0078, #8300e6);
        padding: 10px;
      }

      .package-button-cancel::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #ff0078, #8300e6);
        border-radius: 22.88px;
        z-index: -1;
        padding: 1px;
        -webkit-mask: linear-gradient(black, black) content-box,
          linear-gradient(black, black);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
      }

      .package-button-cancel:hover::before {
        padding: 0; /* Border efektini kaldır */
        background: none; /* Gradient kaldır */
      }

      // .package-button-cancel:hover::before {
      //   filter: brightness(1.2);
      //   animation: border-gradient 2s infinite;
      // }

      .package-button-selected {
        margin-top: 30px;
        width: 10vw;
        height: 3vw;
        // background-color: #7820f3;
        background: linear-gradient(90deg, #ff0078, #8300e6);
        border-radius: 22.88px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: "Open Sans", sans-serif;
        font-size: 1vw;
        line-height: 2vw;
        color: #fff;
        pointer-events: none;
        cursor: pointer;
      }
      .ribbon {
        position: absolute;
        width: 13vw;
        height: 2.5vw;
        top: 1.5vw;
        right: -50px;
        background: linear-gradient(90deg, #ff0078, #8300e6);
        color: white;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 5px 20px;
        text-align: center;
        transform: rotate(45deg);
        z-index: 1;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      }
      .cancel-package-button {
        margin-top: 20px;
        width: 160px;
        height: 50;
        background-color: #f07a22;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Open Sans", sans-serif;
        font-size: 0.8vw;
        line-height: 2vw;
        color: black;
        cursor: pointer;
      }
    }

    .package-info {
      color: white;
      font-size: 18px;
    }
    .package-icon {
      width: 90%;
      margin-top: 20px;
    }
  }
  .purchase-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    .purchase-text-main {
      color: #e675f8;
      font-size: 25px;
      border-bottom: 2px solid #e675f8;
      width: 500px;
      margin-bottom: 25px;
      text-align: center;
      font-family: "Open Sans", sans-serif Black;
    }
  }
  .purchase-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .purchase-package {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #b783ff;
    background: #273341;
    .purchase-icon {
      width: 80%;
      margin-top: 20px;
    }
    .purchase-info {
      margin-top: 80px;
    }
    .purchase-name {
      font-family: "Open Sans", sans-serif;
      font-size: 0.8vw;
      line-height: 2vw;
      color: #b783ff;
    }
    .purchase-desc {
      font-family: "Open Sans", sans-serif;
      font-size: 0.8vw;
      line-height: 2vw;
      color: white;
    }
    .purchase-button {
      width: 160px;
      height: 50;
      background-color: #b783ff;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: "Open Sans", sans-serif;
      font-size: 0.8vw;
      line-height: 2vw;
      color: black;
      cursor: pointer;
    }
    .purchase-info-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      .purchase-change {
        color: #f07a22;
        text-decoration: underline;
        cursor: pointer;
        font-size: 12px;
      }
      .purchase-desc {
        width: 200px;
        word-wrap: break-word;
        line-height: 1.2;
      }
    }
    .purchase-info-desc {
      margin-top: 50px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .purchase-button {
        margin-right: 100px;
      }
    }
  }

  .purchase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #291639;
    align-self: center;
    width: 35vw;
    margin-top: 5vw;
    height: 15vw;
    border-radius: 11.88px;
    .purschase-input-container {
      display: flex;
      width: 25vw;
      margin-top: 2vw;
    }
    .purschase-input-button {
      margin-top: 0vw !important;
      border-radius: 0px 4px 4px 0px !important;
      width: 40% !important;
      background: linear-gradient(90deg, #ff0078, #8300e6);
      border: none !important;
    }
    .error-message {
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }

    .purchase-input {
      min-width: 70%;
      min-height: 50px;
      // width: 20%;
      border-radius: 4px 0px 0px 4px;
      background-color: #fff;
      // margin-top: 30px;
      // margin-inline: 50px;
      color: black;
    }
    .purchase-input::placeholder {
      color: #545454;
    }
    .purchase-text {
      width: 25vw;
      margin-top: 4vw;
      color: white;
    }
    @media (max-width: 768px) {
      .purple-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #b783ff;
        width: 40%;
        font-family: "Open Sans", sans-serif;
        font-size: 3.12vw;
        color: white;
        cursor: pointer;
        border-radius: 4px;
        transition: all ease 0.3s;
        border: 2px solid transparent;
        margin-top: 4vw;
      }
    }
    @media (min-width: 768px) {
      .purple-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #b783ff;
        width: 20%;
        font-family: "Open Sans", sans-serif;
        font-size: 1.12vw;
        color: white;
        cursor: pointer;
        border-radius: 4px;
        transition: all ease 0.3s;
        border: 2px solid transparent;
        margin-top: 4vw;
      }
    }
  }

  .purchase-entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 150px;
    .checkbox-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }
    .checkbox-container {
      display: flex;
      flex-direction: column;
    }
    .purchase-input {
      margin-bottom: 30px;
      border-color: #b783ff;
      border-radius: 10px;
    }
    .checkbox-container input[type="checkbox"] {
      width: 10%;
    }
    .checkbox-label {
      color: white;
      word-wrap: break-word;
      width: 400px;
      .no-underline {
        text-decoration: none;
      }

      .underline {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
