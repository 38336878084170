$tivibuSoftBlueColor: #49bed8;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.left {
  display: inline-flex;
  justify-content: left;
}

.right {
  display: inline-flex;
  justify-content: right;
  height: 100%;
}

.category {
  display: flex;
  align-items: center;
  height: 100%;
  color: white;
  transition: all ease 0.5s;
  cursor: pointer;
  padding: 0.5vw 3vw;
  white-space: nowrap;
  position: relative;

  label {
    font-family: 'Open Sans', sans-serif;
    //font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    cursor: pointer;
  }
  img {
    //max-width: 6vw;
    height: auto;
    position: absolute;
    width: 100px;
    max-inline-size: 100%;
    //top: -10%;
    //left: 55%;
    //transform: translate(-55%, 0);
  }

}

.centered {
  justify-content: center;
}

.is-active {
  label {
    color: #f079ff;
  }
}

.movie {
  background-color: #0c1927;
}

.watch-tv-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 113px;
  height: 45px;
  background: transparent
    linear-gradient(
      262deg,
      #5dadb7 0%,
      #dfab75 25%,
      #fc983f 45%,
      #e62f93 78%,
      #bf0ccb 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 23px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
