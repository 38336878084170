@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.vod-detail-container {
  display: flex;
  // align-items: end;
  position: relative;
  justify-content: center;
  scroll-behavior: smooth;
  background-color: #1b1629;
  border-radius: 8px;

  .vod-detail-box {
    width: 100%;
    height: 100vh;
    position: absolute;
    color: whitesmoke;
    position: relative;
    overflow-y: auto;
    border-radius: 8px;

    .poster-wrapper {
      width: 100%;
      height: auto;
      position: relative;
      min-height: 500px;

      .close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5vw;
        max-width: 35px;
        height: auto;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 2;
      }

      .back-voddetail {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background: rgba($color: #ffffff, $alpha: 0.3);
        border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
      }

      img {
        width: 100%;
        height: auto;
      }

      .gradient {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #1b1629 0%, #1b162909 100%);

        z-index: 1;
      }
    }

    scrollbar-color: white transparent;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      // background: #fff;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      // background: rgb(209, 209, 209);
    }
  }

  .vod-detail-back-icon {
    cursor: pointer;
    color: white;
    top: 15px;
    margin-right: 10px;
    margin-top: 15px;
    opacity: 0;
    z-index: -1;

    &.show {
      opacity: 1;
      z-index: 5;
    }
  }
}

.continue-label {
  display: flex;
  align-items: center;
  font: normal normal normal 26px/36px Open Sans;
  letter-spacing: 0px;
  color: #cccccc;
  margin-left: 15px;
}

.vod-detail-button-wrapper {
  display: flex;
  align-items: center;
}

.vod-detailcontainer-absolute {
  z-index: 2;
  position: absolute;
  // bottom: 0;
  left: 0;
  padding: 0 48px;
  width: 100%;

  // @include responsive(1600px) {
  //     bottom: -70px;
  // }
}

.episode-text {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  color: white;
}

.vod-detail-watch-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  /* Fill the inside with white */
  background-origin: border-box;
  border: 0.2vw solid transparent;
  cursor: pointer;
  color: #1b0539;
  width: 9.75vw;
  height: 3.12vw;

  .icon {
    display: flex;
    align-items: center;
    width: 1.75vw;
    height: 1.75vw;
    font-size: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.vod-detail-play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  width: 9.75vw;
  height: 3.12vw;
  border-radius: 3.12vw;
  font-size: 1.25vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #0c1927;
  transition: all ease 0.3s;
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  background-image: linear-gradient(
    90deg,
    rgb(44, 191, 199) 0%,
    rgb(197, 17, 140) 100%
  );
  /* Fill the inside with white */
  background-origin: border-box;
  box-shadow: inset 0 100vw white;
  /* A transparent border, so the very edge of the button shows through */
  border: 0.2vw solid transparent;
  cursor: pointer;
  color: #1b0539;

  .icon {
    display: flex;
    align-items: center;
    width: 1.75vw;
    height: 1.75vw;
    font-size: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }

  &:hover {
    background-color: rgba($color: white, $alpha: 0.7);
  }
}

.vod-detail-select-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 150px;
  height: 55px;
  border: 1px solid white;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: white;
  margin-right: 19px;
  cursor: pointer;
  transition: all ease 0.3s;

  .icon {
    display: flex;
    align-items: center;
    color: white;
    margin-left: 25px;
    font-size: 20px;
  }

  .arrow-down {
    width: 0;
    height: 0;
    border: 6px solid;
    border-color: white transparent transparent transparent;
    border-bottom: 0px solid;
  }

  .transparent {
    border-color: transparent !important;
  }

  &.opened {
    .arrow-down {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid white;
      border-top: 0px solid;
    }
  }

  .options {
    display: none;
    position: absolute;
    top: 55px;
    left: 0;

    &.show {
      display: flex;
      flex-direction: column;
    }

    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 55px;
      border-radius: 4px;
      background-color: #fff;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      margin-top: 1px;
      color: #0c1927;
    }
  }
}

.vod-detail-rent-button {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 150px;
  padding: 0 15px;
  height: 55px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  background: #ffffff33 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  cursor: pointer;
  transition: all ease 0.3s;
  margin-right: 15px;
  color: white;

  .bundle-box {
    margin-left: 20px;
    display: flex;
    align-items: center;
    color: #00deff;

    span {
      display: flex;
      margin-right: 10px;
    }

    label {
      cursor: pointer;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: 25px;
    font-size: 20px;
  }

  &:hover {
    background-color: white;
    color: #0c1927;
  }
}

.vod-detail-row {
  margin: 10px 0;
  font-family: "Open Sans", sans-serif;
}

.metadata-container {
  margin: 20px 48px;
  // padding: 55px 90px;
  font-family: "Open Sans", sans-serif;

  // @include responsive(1600px) {
  //     margin-top: 90px;

  // }

  .title {
    font-size: 34px;
    color: #fff;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    line-height: 26px;
  }

  .vod-box {
    display: flex;
    margin-top: 30px;

    .vertical-poster {
      width: 125px;

      img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }

    .info-box {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-family: "Open Sans", sans-serif;
        font-size: 18px;
        color: white;
        margin-bottom: 20px;
      }

      .org-title {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        color: #9b9b9b;
        margin: 10px 0;
      }

      .info {
        display: flex;

        label {
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          line-height: 30px;
          color: #9b9b9b;
        }

        span {
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 30px;
          color: white;
          margin-left: 5px;

          .date {
            display: flex;

            .tire {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }

  .director-poster-box {
    width: 14%;
    margin-top: 15px;
    cursor: pointer;

    .director-name {
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      color: #9b9b9b;
      margin-top: 18px;
      text-align: center;
    }
  }
}

.episode-bookmark-progress {
  position: absolute;
  width: calc(100%);
  height: 1px;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);

  .progress {
    height: 100%;
    width: 0;
    background-color: #00deff;
  }
}
