@import "../../style/common";

$white: #fff;

.login-title {
  width: 63.75%;
  @include login-text(Swis721HvBT, 36px, auto, 2%);
}

.login-subtitle {
  @include login-text(SFUITextLight, 14px, 26px, 2.8%);
}

.easy-login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @include login-button(SFUITextBold, #009bdf, 2%, $white, 300px, 45px, 45px);
  .chip {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    position: absolute;
    width: 45px;
    height: 20px;
    line-height: 20px;
    background: #003865;
    border-radius: 10px;
    color: $white;
    font-size: 10px;
    text-align: center;
    right: 10px;
    top: -10px;
  }
}

.article-1 {
  @include login-text(SFUITextLight, 14px, 16px, 1%);
  width: 30px;
  line-height: 16px;
}

.one-password-button {
  @include login-button(
    SFUITextSemibold,
    transparent,
    1.1%,
    $white,
    300px,
    45px,
    45px
  );
  border: 1px solid $white;
}

@media (width >= 1920px) and (height >= 900px) {
  .login-title {
    @include login-text(
      Swis721HvBT,
      52px !important,
      46px !important,
      2% !important
    );
  }

  .easy-login-button {
    @include login-button(
      SFUITextBold,
      #009bdf,
      3.6%,
      $white,
      450px !important,
      65px !important,
      65px !important
    );
  }
}

@media (width >= 1920px) {
  .login-subtitle {
    @include login-text(SFUITextLight, 22px, 26px, 2.7%);
  }

  .easy-login-button {
    @include login-button(SFUITextBold, #009bdf, 2%, $white, 450px, 65px, 65px);
  }

  .one-password-button {
    @include login-button(
      SFUITextSemibold,
      transparent,
      1.1%,
      $white,
      450px,
      65px,
      65px
    );
  }
}

@media (height >= 900px) {
  .login-title {
    @include login-text(Swis721HvBT, 46px, auto, 4%);
  }

  .easy-login-button {
    @include login-button(
      SFUITextBold,
      #009bdf,
      3.6%,
      $white,
      300px,
      45px,
      45px
    );
  }
}
