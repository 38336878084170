@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

// @font-face {
//   font-family: ''Open Sans', sans-serif';
//   src: local(''Open Sans', sans-serif'), url(../assets/fonts/segoeui/Segoe_UI.ttf) format('truetype');
// }

@font-face {
  font-family: "Open Sans", sans-serif;
  src: url(../assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

// @font-face {
//   font-family: 'ProximaNova';
//   src: local('ProximaNova'), url(../assets/fonts/PROXIMANOVA-REGULAR.woff) format('woff');
// }

// @font-face {
//   font-family: 'ProximaNovaBlack';
//   src: local('ProximaNovaBlack'), url(../assets/fonts/PROXIMANOVA-BLACK.woff) format('woff');
// }

// @font-face {
//   font-family: 'SFUIText';
//   src: local('SFUIText'), url(../assets/fonts/SFUIText-Regular.woff) format('woff');
// }

// @font-face {
//   font-family: 'SFUITextSemiBold';
//   src: local('SFUITextSemiBold'), url(../assets/fonts/SFUIText-Semibold.woff) format('woff');
// }

// @font-face {
//   font-family: 'SFUITextBold';
//   src: local('SFUITextBold'), url(../assets/fonts/SFUIText-Bold.woff) format('woff');
// }

// @font-face {
//   font-family: 'SFUITextMedium';
//   src: local('SFUITextMedium'), url(../assets/fonts/SFUIText-Medium.woff) format('woff');
// }

// @font-face {
//   font-family: 'SFUITextLight';
//   src: local('SFUITextLight'), url(../assets/fonts/SFUIText-Light.woff) format('woff');
// }

// @font-face {
//   font-family: 'Swis721HvBT';
//   src: local('Swis721HvBT'), url(../assets/fonts/Swis721_Hv_BT_Heavy.woff) format('woff');
// }

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import "../components/carousel/Carousel.scss";
@import "../components/iconRender/IconRender.scss";
@import "../components/mainboard/Mainboard.scss";
@import "../components/navbar/Navbar.scss";
@import "../views/liveTv/LiveTv.scss";
@import "../components/channelListWidget/ChannelListWidget.scss";
@import "../components/channelListItem/ChannelListItem.scss";
@import "../components/genre/Genre.scss";
@import "../components/channelInfo/ChannelInfo.scss";
@import "../components/verticalProgramListItem/VerticalProgramListItem.scss";
@import "../components/vodDetail/VodDetail.scss";
@import "./react-router-modal.css";
@import "../components/episodeItem/EpisodeItem.scss";
@import "../components/vodGrid/VodGrid.scss";
@import "../components/search/Search.scss";
@import "../components/notification/Notification.scss";
@import "../views/record/Records.scss";
@import "./Utility.scss";
@import "../components/profileList/Profile.scss";
@import "../components/profileSettings/ProfileSettings.scss";
@import "../views/profileSelection/ProfileSelection.scss";
@import "../components/payment/Payment.scss";
@import "../components/programCarousel/ProgramCarousel.scss";
@import "../views/tvGuide/TvGuide.scss";
@import "../components/tvGuideRow/TvGuideRow.scss";
@import "../components/packageList/PackageList.scss";
@import "../components/videoPlayer/VideoPlayer.scss";
@import "../components/verticalProgramList/VerticalProgramList.scss";
@import "../components/wireCard/WireCard.scss";
@import "../components/recordItem/RecordItem.scss";
@import "../components/programDetail/ProgramDetail.scss";
@import "../components/categoryCarousel/CategoryCarousel.scss";
@import "../components/userRecordItem/UserRecordItem.scss";
@import "../components/trailerPlayer/TrailerPlayer.scss";
@import "../components/ppvPayment/PpvPayment.scss";
@import "../components/mainboardThumbnail/MainboardThumbnail.scss";
@import "../components/channelCarousel/ChannelCarousel.scss";
@import "../components/contentGrid/ContentGrid.scss";
@import "../components/miniVodDetail/MiniVodDetail.scss";
@import "../components/castCarousel/CastCarousel.scss";
@import "../components/TivibuSettings/TivibuSettings.scss";
@import "../components/videoAlert/VideoAlert.scss";

@import "../components/footer/Footer.scss";

body {
  background: #1b1629;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  .ad-component {
    transition: top 0.5s ease, position 0.5s ease;
  }

  .ad-component.amir {
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    top: 100px;
    z-index: 500;
  }

  .main {
    position: relative;
    display: flex;
    .sub-main {
      flex: 1;
      margin-left: auto;
      margin-right: 50px;
      overflow: hidden;
    }

    .sub-main > * {
      margin-bottom: 10px;
    }

    .sub-main > :last-child {
      margin-bottom: 0;
    }
  }
}

h1,
h2,
h3,
h4 {
  color: white;
}

a {
  color: unset;
  text-decoration: unset;
}

input {
  background-color: transparent;
  outline: none;
  padding: 15px 15px;
  border: 1px solid #9b9b9b;
  border-radius: 4px;
  width: 100%;
  color: white;
}

input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}

.live-player-box {
  position: sticky;
  top: 0;
  width: 100%;
  height: auto;
}

.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 6;

  &.full-window {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #00000080;
  }

  .loader {
    top: 50vh;
    left: 50%;
    position: fixed;
    transform: translate(-50%, 0);
    z-index: 10;
  }
}

.ratings {
  display: flex;
  align-items: center;
  color: #9b9b9b;
  font: normal normal normal 26px/36px Open Sans;
  justify-content: flex-end;

  .rating {
    display: flex;
    align-items: center;
    margin: 0 5px;
  }
}

.no-program-info {
  color: white;
  font-size: 20px;

  &.aligned {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
}

// nex-prev buttons
.direction-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background: rgb(90, 57, 138);
  width: 64px;
  color: white;
  transition: all ease 0.3s;
  cursor: pointer;
  opacity: 0;
  z-index: -1;
}

// Modal styles
.modal-root {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 2000;
}

.tt-modal {
  position: relative;
  top: 30px;
  bottom: 0;
  right: 0;
  // overflow-y: auto;
  // overflow-x: hidden;
  z-index: 20;
  transition: all ease-in-out 0.3s;
  opacity: 0;
  scrollbar-color: white transparent;
}

.modal-root.modal-open .tt-modal {
  opacity: 1;
}

.tt-modal::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.tt-modal::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.tt-modal::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Handle on hover */
.tt-modal::-webkit-scrollbar-thumb:hover {
  background: rgb(209, 209, 209);
}

.vod-modal {
  width: 960px;
  margin: auto;
  // left: calc(50% - 25vw);
}

.record-option-modal {
  width: 53vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-modal {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
}

.payment-modal {
  width: 60vw;
  display: flex;
  background-color: #0c1927;
}

.settings-modal {
  width: 100%;
}

.settings-modal.extended {
  width: 70vw;
  left: calc(50% - 35vw);
}

.back-icon {
  position: sticky;
  cursor: pointer;
  color: white;
  top: 15px;
  margin-right: 10px;
  margin-top: 15px;
  opacity: 0;
  z-index: -1;
}

.back-icon.show {
  opacity: 1;
  z-index: 5;
}

// Close Button

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: white;
  }

  &.black {
    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 33px;
      width: 2px;
      background-color: black;
    }
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.m-r-2 {
  margin-right: 20px;
}

.program-label-title {
  font-size: 16px;
}

.label-content {
  font-size: 16px;
  color: #cccccc;
}

.unselected-category {
  width: 100%;
  height: 100%;
  background-color: #0b1c268f;
  position: absolute;
  top: 0;
  right: 0;
  // padding: 3px;
}

.infinite-scroll-component {
  // overflow-x: hidden !important;
}

.advertisement-box {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 120px;
  bottom: 0;

  &.show {
    display: flex;
    z-index: 10;
    background: transparent
      linear-gradient(180deg, #00000000 0%, #000000b3 11%, #000000 100%) 0% 0%
      no-repeat padding-box;
  }

  &.hide {
    background: none;
    z-index: -5;
    visibility: hidden;
  }
}

// HOME PAGE AD BANNER STYLES
.box-ad {
  position: relative;

  .close-ad {
    position: absolute;
    right: -1em;
    top: 0;
    color: white;
    cursor: pointer;
  }
}

.login-container {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.main-scroller {
  overflow-y: auto;
  // max-height: 80vh !important;

  // @include responsive(1400px) {
  //   height: 67vh !important;
  // }

  scrollbar-width: none;
}

.main-scroller::-webkit-scrollbar {
  width: 0px;
}

.ppv-modal {
  width: 30vw;
  left: calc(50% - 15vw);
  display: flex;
  align-items: center;
  justify-content: center;
}

.vod-list-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;
  scrollbar-color: white transparent;

  @include responsive(1400px) {
    height: 67vh !important;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fff;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(209, 209, 209);
  }
}

// profile pin input style

.profile-input {
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  border: 2px solid white;
  padding: 1.04vw 1.15vw;
  -moz-appearance: textfield;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: "Open Sans", sans-serif;
    font-size: 1vw;
    color: rgba($color: #fff, $alpha: 0.9);
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: "Open Sans", sans-serif;
    font-size: 1vw;
    color: rgba($color: #fff, $alpha: 0.9);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    font-family: "Open Sans", sans-serif;
    font-size: 1vw;
    color: rgba($color: #fff, $alpha: 0.9);
  }

  &.pin {
    -webkit-text-security: disc;
    -moz-text-security: disc;
  }
}

//mini and vod detail common css
.action-button {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  // width: 40px;
  // height: 40px;
  // font-size: 20px;
  width: 34px;
  height: 34px;
  font-size: 15px;
  margin-right: 12px;
  border-radius: 50%;
  transition: all ease 0.3s;
  cursor: pointer;

  .icon {
    display: flex;
  }

  &.primary {
    transition: all ease 0.3s;
    background-color: rgba($color: #fff, $alpha: 0.8);
    color: #0c1927;

    &:hover {
      background-color: #d5dc36;
    }
  }

  &.secondary {
    background-color: rgba($color: #fff, $alpha: 0.2);
    color: white;

    &:hover {
      transform: scale(1.1);
      background-color: rgba($color: #fff, $alpha: 0.4);
    }

    &.selected {
      background-color: white;
      color: #0c1927;
    }

    .score {
      position: absolute;
      bottom: -20px;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      color: #595f65;
      white-space: nowrap;
    }
  }

  &.secondary-record {
    background-color: rgba($color: #fff, $alpha: 0.2);
    color: #00deff;
  }

  &.absolute {
    position: absolute;
    bottom: 12px;
    z-index: 10;
    right: 0;
  }

  .text {
    position: absolute;
    bottom: -20px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #595f65;
    white-space: nowrap;
  }
}

.custom-share {
  display: flex;
  align-items: center;
  position: absolute;
  // bottom: -70px;
  // left: 10px;
  background-color: #1b1629;
  padding: 15px 10px;
  // border: none;
  opacity: 0;
  z-index: -1;
  transition: all ease 0.3s;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  width: 30vw;

  &::before {
    content: "";
    position: absolute;
    top: 60%;
    left: -10px; /* Oku sol tarafa yerleştir */
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 10px 0; /* Sol tarafa ok oluştur */
    border-color: transparent #1b1629 transparent transparent; /* Sağ kenar renkli */
  }

  &.tooltip-left {
    border-radius: 0 8px 8px 8px;
    left: 140%;
  }

  &.tooltip-right {
    border-radius: 8px 0 8px 8px;
    right: 0;
  }

  &.player-tooltip {
    top: 40px;
    flex-direction: column;
    bottom: unset;

    .tooltip-button {
      margin-right: 0;
      margin-bottom: 7px;
    }
  }

  &.open {
    opacity: 1;
    z-index: 10;
    visibility: visible;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  .tooltip-text {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    white-space: nowrap;
  }

  .tooltip-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: 1.5px solid #6e8496;
    background-color: white;
    margin-right: 7px;

    .tooltip-icon {
      display: flex;
    }
  }

  .share-input-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0px 10px 0px;
  }

  .share-input {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    padding: 10px 50px 10px 15px; /* Sağda buton için boşluk bırak */
    border: 1px solid #ccc;
    border-radius: 11.88px;
    width: 100%;
    color: #fff;
    background-color: #1b1629;
  }

  .copy-button {
    position: absolute;
    top: 50%;
    right: 0; /* Sağda konumlandır */
    transform: translateY(-50%);
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    padding: 10px 10px;
    border: none;
    border-radius: 11.88px;
    border: 1px solid #fff;
    background: linear-gradient(90deg, #ff0078, #8300e6);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .copy-button:hover {
    background-color: #0a1521;
  }
}

.custom-tooltip {
  display: flex;
  align-items: center;
  position: absolute;
  // bottom: 0px;
  background-color: #fff;

  padding: 15px 10px;
  border: none;
  opacity: 0;
  z-index: -1;
  transition: all ease 0.3s;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;

  .tooltip-wrapper {
    display: flex;
  }

  &::before {
    content: "";
    position: absolute;
    top: 60%;
    left: -10px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
  }

  &.tooltip-left {
    border-radius: 8px 8px 8px 8px;
    left: 140%;
  }

  &.tooltip-right {
    border-radius: 8px 0 8px 8px;
    right: 0;
  }

  &.player-tooltip {
    top: 40px;
    flex-direction: column;
    bottom: unset;

    .tooltip-button {
      margin-right: 0;
      margin-bottom: 7px;
    }
  }

  &.open {
    opacity: 1;
    z-index: 10;
    visibility: visible;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  .tooltip-text {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: bold;
    margin-right: 20px;
    color: #000;
    white-space: nowrap;
  }

  .tooltip-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: 1.5px solid #6e8496;
    background-color: white;
    margin-right: 7px;

    .tooltip-icon {
      display: flex;
      .share-icon {
        circle {
          fill: white;
        }
        path {
          fill: black;
        }
      }
    }
  }
}

.vod-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .vod-info {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #9b9b9b;

    &.white {
      color: white;
    }

    &.l-font {
      font-size: 18px;
    }
  }

  .vod-info-seperator {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #9b9b9b;
    margin: 0 10px;
  }
}

.imdb-point {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #00deff;
  text-decoration: none;
}

.yellow-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b783ff;
  width: 100%;
  height: 3.12vw;
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  color: #0c1927;
  cursor: pointer;
  border-radius: 4px;
  transition: all ease 0.3s;
  border: 2px solid transparent;
  margin-bottom: 2.6vw;
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.tr-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100%;
  height: 3.12vw;
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: all ease 0.3s;
  border: 2px solid white;
}

.red-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff3b30;
  width: 11vw;
  height: 3.12vw;
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid transparent;
  margin-top: 5vw;
}

.continue-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.12vw;
  font-weight: bold;
  font-size: 1vw;
  color: rgba($color: white, $alpha: 0.9);
  cursor: pointer;
  border-radius: 4px;
  transition: all ease 0.3s;
  width: 200px;
  margin: 0 30px;
  //animation-name: fillBackground;
  animation-duration: 30s;
}

@keyframes fillBackground {
  from {
    box-shadow: inset 0 0 0 0 transparent;
  }

  to {
    box-shadow: inset 200px 0 0 0 #ffffff40;
  }
}

.not-found {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
}

// Selectbox css
.primary-select-box-options {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50px;
  right: 0;
  width: 13vw;
  max-height: 300px;
  background-color: #291639;
  opacity: 0;
  z-index: -1;
  overflow-y: auto;
  transition: opacity ease 0.3s;
  scrollbar-color: white transparent;
  border: 1px solid #3d4e5a40;
  pointer-events: none;

  &.show {
    opacity: 1;
    z-index: 21;
    pointer-events: auto;
  }

  .option {
    padding: 15px 0;
    cursor: pointer;
    direction: ltr;
    // min-width: 40%;

    .option-text {
      position: relative;
      font-family: "Open Sans", sans-serif;
      color: white;
      font-size: 16px;
      white-space: nowrap;
    }

    .selected {
      color: #b783ff;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 3px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(209, 209, 209);
  }
}

.link {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.child-container {
  position: relative;
  background: rgb(220, 8, 146);
  background: linear-gradient(
    0deg,
    rgba(220, 8, 146, 1) 0%,
    rgba(122, 169, 165, 1) 94%,
    rgba(106, 170, 176, 1) 100%
  );

  .child-gradient {
    position: absolute;
    width: 100%;
    height: 485px;
    background: linear-gradient(
      180deg,
      rgba(12, 25, 39, 1) 0%,
      rgba(12, 25, 39, 0) 100%
    );
  }
}

.mytv-program-container {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 5px;
  width: calc(100% + 5px);

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 3px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 3px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(209, 209, 209);
  }
}

.landing-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .main-loader-wrapper {
    display: flex;
    align-items: center;
    .main-loader {
      width: 6vw;

      img {
        width: 100%;
        height: auto;
      }
    }
    .loading-text {
      display: inline-block;
      font-size: 2vw;
      font-family: "Open Sans", sans-serif;
      color: white;
    }
  }
}

.loading-gif {
  display: flex;
  width: 30px;
  height: 30px;

  img {
    width: 100%;
    height: auto;
  }
}

.pip-player {
  position: fixed;
  width: 384px;
  height: 216px !important;
  bottom: 3vh;
  right: 3vw;
  height: auto;
  z-index: 15;
}

//  tvlockicon and tv favorite icon style due to safari bug
.channel-list-item-icons {
  width: 1em;
  height: 1em;
}

.account-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
}

.account-success-icon {
  width: 150px;
  height: auto;
}
.account-text {
  color: white;
  font-size: 30px;
  text-align: center;
}

.account-feedback {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 100px;
  margin: 50px 0;
  max-width: 800px;
  .text-section {
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    gap: 40px;
  }
  img {
    max-width: 250px;
    height: auto;
  }
}

.loading-box {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
}
.chatbot-display {
  display: none !important;
}
