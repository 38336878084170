.channel-info-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .program-title {
        font: normal normal normal 1.2vw 'Open Sans', sans-serif;
        color: white;

    }

    .program-data-row {
        display: flex;
        flex-direction: row;
        color: #A1A1A1;
        margin: 20px 0;

        .program-rating {

            align-items: center;
            display: flex;
            font-family: 'Open Sans', sans-serif;
            font-size: .8vw;
            line-height: 3px;

            &.icon {
                margin-left: 10px;
                font-size: 1.3vw;
            }

            &.genre {
                margin-left: 10px;
            }
        }
    }

    .program-description {
        font: normal normal 300 .85vw 'Open Sans', sans-serif;
        letter-spacing: 0px;
        color: #91A3B5;
        margin-top: 15px;

    }

    .program-icons {
        margin-top: 25px;

        .program-icon {
            display: flex;
            width: fit-content;
            height: 70px;
        }
    }

    // .box {
    //     width: 30px;
    //     height: 30px;
    //     background-color: yellow;
    //     border: 1px solid red;
    // }
}

.prev-programs {
    // width: 830px;
}

#vert-program-list {
    position: relative;
    z-index: 11;
    width: 96.2%;
    // background: transparent linear-gradient(180deg, #0B1C2600 0%, #0B1C26 13%, #143345 100%) 0% 0% no-repeat padding-box;
}

