body {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.profile-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-position: center center;

  .profile-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgb(91, 60, 139) 0%, rgb(0, 0, 0) 100%);
    z-index: -1;
  }

  .profile-gradient-2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1b1629;
    z-index: -1;
  }

  .row {
    display: flex;
    position: relative;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 5vw;
    z-index: 10;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 179.55px;
      height: 55.575px;
      background-color: #fff;
      border-radius: 3.25vw;
      font-family: "Open Sans", sans-serif;
      background-image: linear-gradient(
        90deg,
        rgb(44, 191, 199) 0%,
        rgb(197, 17, 140) 100%
      );
      background-origin: border-box;
      box-shadow: inset 0 100vw white;
      border: 0.2vw solid transparent;
      cursor: pointer;
      .logout {
        display: flex;
        align-items: center;
        color: #1b0539ce;
        cursor: pointer;
        //font-size: 1vw;
      }
    }
  }

  .profile-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    .profile-selection-wrapper {
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .profile-selection {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 80%;
      justify-content: space-evenly;
      align-items: flex-start;
    }
    .profile-back-btn {
      margin: "0px -50px";
    }
    .profile-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      //margin-top: 8vw;
      z-index: 10;

      .text {
        font-family: "Open Sans", sans-serif;
        font-size: 1.82vw;
        line-height: 5vw;
        color: #b783ff;
        margin-bottom: 3vw;
      }

      .text-login {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Open Sans", sans-serif;
        font-size: 1.82vw;
        line-height: 5vw;
        color: white;
        margin-bottom: 3vw;
      }

      .profile-items-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        .slick-next {
          font-size: 15px !important;
        }
        .slick-arrow.slick-next.slick-disabled {
          opacity: 0;
          cursor: default;
        }
        .slick-prev {
          font-size: 15px !important;
        }
        .slick-arrow.slick-prev.slick-disabled {
          opacity: 0;
          cursor: default;
        }
        .slick-prev:before,
        .slick-next:before {
          content: "" !important;
        }
        .profile-item {
          display: flex;
          position: relative;
          flex-direction: column;
          align-items: center;
          max-width: 12vw !important;

          .profile-avatar {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            padding: 20px;
            // border-radius: 50%;
            // border: 3px solid transparent;
            transition: all ease 0.3s;
            transition-delay: 0.1s;
            cursor: pointer;

            img {
              width: 100%;
            }

            .settings-icon-wrapper {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              bottom: 0;
              width: calc(100% - 40px);

              .settings-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2vw;
                height: 2vw;
                font-size: 0.9vw;
                background-color: #112134;
                border-radius: 50%;
                color: white;
              }
            }
          }

          .profile-name {
            font-family: "Open Sans", sans-serif;
            font-size: 1.25vw;
            color: #ffffff;
            text-align: center;
            max-width: 12vw !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }

          .edit-text {
            font-family: "Open Sans", sans-serif;
            font-size: 0.75vw;
            color: #e675f8;
            text-align: center;
            cursor: pointer;
          }
        }
      }

      .profile-item.add {
        background-color: #999999;
      }
    }
    .profile-settings-line {
      width: 1px;
      height: 100%;
      background-color: #5a398a;
      margin: 2vw;
    }
    .profile-settings {
      display: flex;
      flex-direction: column;
      //margin-top: 8vw;
      z-index: 10;

      .profile-settings-wrapper {
        display: flex;
        justify-content: space-evenly;
        width: 500px;
        height: 500px;
      }

      .profile-div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #b783ff;
        border-radius: 30px;
        padding: 5px;
        width: 200px;
        height: 190px;
        transition: all 0.3s ease;
        &:hover,
        &:active {
          background-color: #5b3c8b;
          cursor: pointer;
        }
      }
      .icon:hover {
        transform: scale(1.1);
      }

      .icon-log {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #b783ff;
        border-radius: 30px;
        padding: 5px;
        width: 200px;
        height: 190px;
        margin-top: 9vw;
        &:hover,
        &:active {
          background-color: #5b3c8b;
          cursor: pointer;
        }
      }

      .icon-log:hover {
        transform: scale(1.1);
      }
      .icon-log.clicked .icon-img {
        transform: rotate(360deg);
      }

      .icon-img {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 65%;
        height: auto;
        padding: 20px;
        transition: all ease 0.3s;
        transition-delay: 0.1s;
        cursor: pointer;
        transition: opacity 0.3s ease;
      }

      .icon-text {
        font-family: "Open Sans", sans-serif;
        font-size: 1.2vw;
        color: white;
        margin-top: 0.5vw;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Open Sans", sans-serif;
        font-size: 1.82vw;
        line-height: 5vw;
        color: #b783ff;
        margin-bottom: 3vw;
      }
    }
  }

  .pin-code-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 10vw;
    z-index: 10;

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 3.25vw;
      font-family: "Open Sans", sans-serif;
      background-image: linear-gradient(
        90deg,
        rgb(44, 191, 199) 0%,
        rgb(197, 17, 140) 100%
      );
      background-origin: border-box;
      box-shadow: inset 0 100vw white;
      border: 0.2vw solid transparent;
      cursor: pointer;
      .logout {
        display: flex;
        align-items: center;
        color: #1b0539ce;
        cursor: pointer;
        //font-size: 1vw;
      }
    }

    .text {
      font-family: "Open Sans", sans-serif;
      font-size: 3.4vw;
      line-height: 3.7vw;
      color: #f9faff;
      margin-bottom: 3vw;
      margin-top: 7vw;
    }

    .text-logging {
      font-family: "Open Sans", sans-serif;
      font-size: 1.85vw;
      color: #f9faff;
      opacity: 0;
      // transition: all ease .3s;
      margin-bottom: 3vw;
      margin-top: 3vw;

      &.show {
        opacity: 1;
      }
    }

    .profile-item {
      width: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;

      .profile-avatar {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 11.4vw;
        //height: 12vw;
        border-radius: 50%;

        .profile-loading {
          width: 24vw;
          height: 24vw;
          top: -6vw;
          left: -6.3vw;
          position: absolute;
          background: url("../../assets/images/circles_new.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          animation-name: profileLoading;
          animation-duration: 2s;
          animation-iteration-count: infinite;
        }

        img {
          width: 100%;
          z-index: 10;
        }
      }

      .profile-name {
        font-family: "Open Sans", sans-serif;
        font-size: 1.66vw;
        color: rgba($color: #fff, $alpha: 0.9);
        text-align: center;
        margin: 1vw 0;
        z-index: 10;
      }
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      right: 130px;
      width: 36px;
      height: 36px;
      background: rgba($color: #ffffff, $alpha: 0.3);
      border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
      border-radius: 50%;
      cursor: pointer;
      margin-top: 6vw;
      z-index: 2;
    }
  }
}

#profileListForm {
  width: 20vw;
  margin: auto;
}

@keyframes profileLoading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
