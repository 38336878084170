.footer-container {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  bottom: 0;

  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .footer-top-line {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 8.476vw;
      padding-right: 90px;
      background-color: rgb(91, 60, 139);
      .label {
        text-decoration: none;
        padding-right: 3.12vw;

        font-size: 0.936vw;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 0.9;
        cursor: pointer;
      }

      .footer-icon {
        display: flex;
        align-items: center;
        padding: 0.26vw 0;
        gap: 0.52vw;
        .span {
          svg {
            max-width: 57px;
            height: auto;
            width: 3.5vw;
            height: 3.5vw;
          }
        }
      }
    }

    .footers {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.52vw 25vw 0.52vw;
      //background-color: #6c3eac;
      width: 100%;
      height: 70px;

      .label {
        letter-spacing: 0px;
        font-size: 0.73vw;

        color: rgb(185, 191, 198);
        opacity: 0.9;
        cursor: pointer;
      }
    }
    .footers-info {
      display: flex;
      align-items: center;
      .label {
        margin-right: 10px;
      }
    }
  }

  .footers-column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #273341; //tasarımdaki rengin color pickerden alınan renk kodu

    //background: #A5B2C31A; //tasarımda verilen renk kodu
    .footer-bottom-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70%;
      height: 3.9vw;
      opacity: 1;

      .footer-bottom {
        display: flex;
        align-items: center;

        .label {
          text-decoration: none;
          padding-right: 2.444vw;
          letter-spacing: 0px;
          font-size: 0.73vw;

          color: #707c8b;
          opacity: 0.9;
          cursor: pointer;
        }
      }
    }
  }
}
