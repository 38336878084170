.profile-selection-section {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.52);

    .box {
        display: flex;
        width: 100%;
        height: 80%;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .profile-wrapper {
            display: flex;
            justify-content: space-around;
            align-items: flex-start;
            width: 50vw;
            min-height: 100px;

            .profile-item {
                max-width: 11vw;
                display: flex;
                // flex: 1;
                // width: 5vw;
                flex-direction: column;
                align-items: center;
                transition: all ease 0.3s;
                transition-delay: 0.1s;
                position: relative;
                left: 0;
                visibility: visible;
                opacity: 1;

                .profile-avatar {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: 100%;
                    padding: 8px;
                    height: auto;
                    border-radius: 50%;
                    border: 4px solid transparent;
                    transition: all ease 0.3s;
                    transition-delay: 0.1s;
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    &:hover {
                        border: 4px solid rgba(255, 255, 255, 0.4);

                    }

                    .profile-loader {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .profile-name {
                    opacity: 0;
                    font: normal normal normal 19px/22px 'Open Sans', sans-serif;
                    color: #FFFFFF;
                    transition: all ease 0.3s;
                    transition-delay: 0.1s;
                    text-align: center;
                    // width: 8vw;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
                }


                &:hover {
                    .profile-name {
                        opacity: 1;
                    }
                }

            }

            .profile-item.selected {
                .profile-name {
                    opacity: 1;
                }

                .profile-avatar {
                    border: none;
                }
            }

            .profile-item.hidden {
                opacity: 0;
                visibility: hidden;
            }

            .profile-item.add {
                background-color: #999999;
            }
        }

        .text {
            font: normal normal 600 26px/35px 'Open Sans', sans-serif;
            letter-spacing: 0px;
            color: #FFFFFF;
        }
    }

    .button-box {
        display: flex;
        justify-content: center;
    }
}

.password-icon {
    position: absolute;
    color: white;
    top: 20%;
    right: 4%;
    cursor: pointer;
}