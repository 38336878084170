.episode-container {
    margin-top: 50px;

    .episode-item {
        cursor: pointer;

        .episode-box {
            display: flex;

            .episode-poster-wrapper {
                position: relative;
                width: 20%;

                &:hover {
                    .episode-gradient {
                        opacity: 1;
                    }
                }

                img {
                    border-radius: 10px;
                }

                .episode-gradient {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color: #0C1927, $alpha: .43);
                    border-radius: 10px;
                    opacity: 0;
                    transition: all ease .3s;

                    .episode-play-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        height: 40px;
                        background-color: rgba($color: white, $alpha: .8);
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }
            }

            .episode-info-wrapper {
                display: flex;
                flex-direction: column;
                width: 80%;
                padding: 0 40px;

                .title-row {
                    display: flex;
                    align-items: center;

                    .episode-title {
                        font-family: 'Open Sans', sans-serif;
                        font-size: 20px;
                        color: white;
                        margin-right: 36px;
                    }

                    .episode-duration {
                        font-family: 'Open Sans', sans-serif;
                        font-size: 20px;
                        color: #9B9B9B;
                    }
                }

                .episode-description-row {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 16px;
                    color: white;
                    margin-top: 16px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3; // max nb lines to show
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                }
            }

        }

        .episode-seperator {
            width: 100%;
            height: 1px;
            background-color: #666F85;
            opacity: .3;
            margin: 40px 0;
        }
    }
}