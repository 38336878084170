.record-option-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 50%;
    align-items: center;
    position: relative;
    padding: 0 3.2vw 3.2vw 3.2vw;
    background-color: #0C1927;

    .record-option-title {
        font-family: 'Open Sans', sans-serif;
        font-size: 1.3vw;
        color: white;
        margin-bottom: 2.2vw;
    }

    .record-option {
        width: 100%;
        text-align: center;
        padding: 15px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1vw;

        &.yellow {
            font-family: 'Open Sans', sans-serif;
            background-color: #D5DC36;
            color: #0C1927;
            margin-bottom: 1vw;
        }

        &.transparent {
            font-family: 'Open Sans', sans-serif;
            background-color: transparent;
            color: white;
            border: 2px solid white;
        }
    }

    &.channel-info {
        width: 100%;
        border-radius: 20px;
        padding: 36px 0;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.program-back-icon {
    cursor: pointer;
    color: white;
    top: 15px;
    margin-right: 10px;
    margin-top: 15px;
    opacity: 0;
    z-index: -1;

    &.show {
        opacity: 1;
        z-index: 5;
    }
}

.program-detail-button {
    display: flex;
    padding: 15px 40px;
    align-items: center;
    justify-content: center;
    transition: ease all 0.3s;
    cursor: pointer;
    border-radius: 4px;
    background-color: rgba($color: white, $alpha: 0.2);
    opacity: 1;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    color: white;
    transition: ease all .3s;

    &.record-button {
        min-width: 217px;
        justify-content: unset;

        &:hover {
            background-color: rgba($color: white, $alpha: 0.3);
            color: white;

            .icon {
                color: white;
            }
        }
    }


    &.play {
        background-color: white;
        color: #0C1927;

        &:hover {
            background-color: rgba($color: white, $alpha: .7);
        }

    }

    &.yellow {
        color: #D5DC36;

        &:hover {
            background-color: white;
            color: #D5DC36;

            .icon {
                color: #D5DC36;
            }
        }
    }

    &.red {
        color: #E9253E;

        &:hover {
            background-color: white;
            color: #E9253E;

            .icon {
                color: #E9253E;
            }
        }

    }

    &.blue {
        color: #00DEFF;

        &:hover {
            background-color: white;
            color: #00DEFF;

            .icon {
                color: #00DEFF;
            }
        }

    }

    .icon {
        display: flex;
    }

    label {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        // letter-spacing: 0px;
        cursor: pointer;
        margin-left: 20px;
    }

    &:hover {
        background-color: white;
        color: black;

        .icon {
            color: black;
        }
    }

}

.container-absolute {
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 48px;
    width: 100%;
}

.container-1 {
    z-index: 2;
    padding: 48px;

    .title {
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        font-weight: bold;
        color: white;
        margin-bottom: 20px;
    }
}


.img-wrapper {
    position: relative;
    width: 100%;
    height: auto;
}

.container-2 {
    position: relative;
    z-index: 2;
    padding: 0 48px;

    .row {
        display: flex;
        align-items: center;


        .genre {
            font-family: 'Open Sans', sans-serif;
            font-size: 23px;
            color: white;

        }

        .date {
            font-size: .936vw;
            font-family: 'Open Sans', sans-serif;
            color: #9B9B9B;
            opacity: 1;
        }

        .ratings {
            font-size: 24px;
            color: rgba(155, 155, 155, 1);
        }

        .synopsis-box {
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            line-height: 26px;
            color: white;
            margin-top: 31px;
        }
    }
}

.container-3 {
    background-color: #060F19;
    padding: 35px 32px;
    margin: 35px 48px;

    .title {
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        line-height: 30px;
    }

    .card {
        margin-top: 30px;

        .vertical-poster {
            width: 125px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .info-box {
            margin-left: 40px;

            .title {
                font-family: 'Open Sans', sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 30px;
                color: white;
            }

            .info {
                display: flex;

                label {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 14px;
                    line-height: 30px;
                    color: #9B9B9B;
                }

                span {
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 30px;
                    color: white;
                    margin-left: 10px;

                    .date {
                        display: flex;

                        .tire {
                            margin: 0 5px;
                        }
                    }
                }
            }
        }
    }
}