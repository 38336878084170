@mixin responsive($query) {
    @media (max-width: + $query) {
        @content;
    }
}


.program-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid #3D4E5A40;
    border-radius: 50px;
    padding: 25px 50px;
    background-color: #291639;
    cursor: pointer;


    .box {
        display: flex;
        align-items: center;
        height: 100%;

        .time {
            flex: 1;
            font: normal normal normal 14px 'Open Sans', sans-serif;
            color: #A1A1A1;
            justify-content: center;
            border-right: 1px solid #767676;
            padding-right: 10px;

        }

        .next {
            background: #0A1624 0% 0% no-repeat padding-box;
        }

    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 75%;

        @include responsive(1400px) {
            width: 70%;
        }

        .title {
            width: 100%;
            font: normal normal normal 1vw 'Open Sans', sans-serif;
            color: white;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: .5vw;

        }

        .genre {
            display: inherit;
            font: normal normal .78vw 'Open Sans', sans-serif;
            color: white;


        }

        .passing-time {
            //  display: flex;
            //  flex-direction: column;
            text-align: right;
            font: normal normal 100 .78vw 'Open Sans', sans-serif;
            color: #FFFFFF;


        }
    }

    .image-icon-group {
        display: flex;
        align-items: center;
        height: 100%;
        margin: auto;

        .box-namespace {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #112134 0% 0% no-repeat padding-box;
            border: 0.7175999283790588px solid #3D4E5A80;
            opacity: 1;
            width: 1.72vw;
            height: 1.72vw;
            border-radius: 50%;
            cursor: pointer;
            margin-right: 10px;
        }

        .progress-bar {
            height: auto;
            align-items: center;
            background: #5A398A;
            padding: 10px 18px;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border: 1.5px solid white;
            border-radius: 20px;
            margin-right: 10px;


            .current-program-play-icon {
                display: flex;
                opacity: 1;
                font-size: 9px;
            }

            .current-program-play-text {
                margin-left: 10px;
                font-family: 'Open Sans', sans-serif;
                font-size: 20px;
                letter-spacing: 0px;
                color: #FFFFFF;
                opacity: 1;
            }



        }

        .rating {
            display: flex;
            color: white;
            font-size: 1.72vw;
            text-align: center;
            opacity: 1;

        }
    }



}


.program-row .info .progress-bar {
    height: 1px;
    background-color: $softBlue;
    margin-top: 3px;
    max-width: 100%;
    padding: 1px;
}




.genre-passingtime {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    color: #587086;
    font: normal normal normal .78vw 'Open Sans', sans-serif;

}

.previousprog {
    background: #291639 0% 0% no-repeat padding-box;

    .box {
        .title {
            color: #FFFFFF;
        }

        .genre-passingtime {
            .genre {
                color: #FFFFFF;

            }

            span {
                color: #FFFFFF;
            }

            .date {
                color: #f47c24;
            }
        }
    }
}