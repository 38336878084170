@mixin login-button(
  $font-family,
  $background,
  $margin-top,
  $color,
  $width,
  $line-height,
  $height
) {
  font-size: 14px;
  background: $background;
  text-align: center;
  width: $width;
  height: $height;
  line-height: $line-height;
  color: $color;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  margin-top: $margin-top;
}

@mixin login-text($font-family, $font-size, $height, $margin-top) {
  height: $height;
  margin-top: $margin-top;
  text-align: center;
  color: $white;
  font-size: $font-size;
}

@mixin login-input($width, $height) {
  &:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
  }
  width: $width;
  height: $height;
  border-radius: 5px;
  border: 2px solid #b783ff;
  background-color: transparent !important;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  padding: 11px 40px 11px 20px !important;
  &::placeholder {
    color: #fff;
    opacity: 0.75;
    font-weight: 200;
  }
}

@mixin input-error($width) {
  color: red;
  display: flex;
  justify-content: left;
  width: $width;
  word-break: break-word;
  p {
    text-align: left;
  }
}
