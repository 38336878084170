.packet {
  background-color: #1b1629;
  border: 1px solid #b783ff;
  max-width: 400px;
  width: 100%;
  height: auto;
  margin: 80px 0;
  padding: 40px 25px;
  p {
    text-align: start;
  }
  .packet-title {
    color: #e675f8;
    margin-top: 60px;
    margin-bottom: 10px;
  }
  .packet-description {
    color: #fff;
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  button {
    margin-top: 80px;
    color: #1b1629;
    background-color: #b783ff;
    padding: 13px 32px;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: 'Open Sans', sans-serif;
    &:hover {
      opacity: 0.79;
    }
  }
}
