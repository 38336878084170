@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.banner-wrapper {
  position: relative;
  min-height: 300px;
  // margin-bottom: 17.5vw;
  // top: -70px;

  .banner-item {
    width: 100%;
    position: relative;
    cursor: pointer;
    outline: none;

    .banner-poster {
      width: 100%;
      height: auto;
      background-size: center, center;
      transition: all ease 0.3s;
    }

    .gradient {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #1b162951;
      background: linear-gradient(0deg, #1b1629 0%, #1b162909 10%);
      z-index: 15;
      top: 0;
      left: 0;
    }

    .transparent {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(27, 22, 41, 0.1);
      z-index: 14;
      top: 0;
      left: 0;
    }

    .info-wrapper {
      position: absolute;
      // bottom: 20%;
      top: 7vw;
      left: 0;
      width: 70%;
      margin-left: 9.5vw;
      height: auto;
      display: flex;
      justify-content: flex-start;
      // align-items: center;
      z-index: 17;
      // background: transparent linear-gradient(180deg, #00000000 0%, #000000BD 40%, #000000 100%) 0% 0% no-repeat padding-box;

      .info-box {
        display: flex;
        // flex-direction: column;
        justify-content: center;
        // width: 60%;
        margin-top: 350px;

        @include responsive(1400px) {
          width: 80%;
        }

        .title-wrapper {
          transition: all ease 0.3s;

          .channel-title,
          .title {
            font-family: "Open Sans", sans-serif;
            font-size: 60px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.9;
            letter-spacing: 2.13px;
            text-align: left;
            color: #fff;

            @include responsive(1400px) {
              font-size: 32px;
            }
          }

          .channel-title {
            font-size: 35px;
          }
        }

        .metadata-box {
          color: white;
          // opacity: 0;
          transition: all ease 0.3s;
          margin-top: 4vw;
          margin-bottom: 2vw;

          .metadata-detail {
            .metadata-row {
              display: flex;
              font-size: 28px;
              margin-top: 20px;
              align-items: center;

              .year,
              .duration,
              .genre,
              .banner-rating {
                display: inline-flex;
                font-family: "Open Sans", sans-serif;
                font-size: 1.1vw;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                text-align: left;
                color: #9b9b9b;
              }

              .banner-rating {
                font-size: 24px;
              }

              .description {
                margin-bottom: 20px;
                opacity: 0.9;
                font-family: "Open Sans", sans-serif;
                font-size: 1vw;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; // max nb lines to show
                -webkit-box-orient: vertical;
              }

              .seperator {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #9b9b9b;
                margin: 0 10px;
              }
            }
          }
        }

        .slider-button {
          display: flex;
          justify-content: center;
          align-items: center;
          // width: 15vw;
          height: 3.12vw;
        }

        .play-button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1vw;
          width: 9.75vw;
          height: 3.12vw;
          border-radius: 3.12vw;
          font-size: 1.25vw;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: left;
          color: #0c1927;
          margin-right: 19px;
          transition: all ease 0.3s;
          background-color: #fff;
          font-family: "Open Sans", sans-serif;
          background-image: linear-gradient(
            90deg,
            rgb(44, 191, 199) 0%,
            rgb(197, 17, 140) 100%
          );
          /* Fill the inside with white */
          background-origin: border-box;
          box-shadow: inset 0 100vw white;
          /* A transparent border, so the very edge of the button shows through */
          border: 0.2vw solid transparent;
          cursor: pointer;
          color: #1b0539;

          .icon {
            display: inline-flex;
            align-items: center;
            // width: 1.75vw;
            // height: 1.75vw;
            font-size: 20px;
            img {
              width: 100%;
              height: auto;
            }
          }

          &:hover {
            background-color: rgba($color: white, $alpha: 0.7);
          }
        }

        .price {
          font-size: 26px;
          letter-spacing: 0px;
          color: #cccccc;

          &.discount {
            text-decoration: line-through;
          }
        }

        .event-button {
          display: flex;
          // width: 3.12vw;
          // height: 3.12vw;
          cursor: pointer;
        }

        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 3.12vw;
          height: 3.12vw;
          border-radius: 50%;
          background-color: #5a398a;
          backdrop-filter: blur(8px);
          -webkit-backdrop-filter: blur(8px);
          margin-right: 8.5px;
          border: 1px solid rgba(255, 255, 255, 0.102);
          box-shadow: 2px 2px 2px 2px
            rgba($color: rgb(82, 78, 78), $alpha: 0.11);

          &:hover {
            background-color: white;

            .icon {
              color: #0c1927;
            }
          }

          &.selected {
            background-color: white;
          }

          .icon {
            display: flex;
            font-size: 1.3vw;
            color: #d8d8d8;

            &.checked {
              color: #0c1927;
            }
          }

          label {
            font: normal normal normal 26px/36px Open Sans;
            letter-spacing: 0px;
            color: #cccccc;
            margin-left: 10px;
            cursor: pointer;

            @include responsive(1400px) {
              font: normal normal normal 16px/26px Open Sans;
            }
          }
        }
      }
    }

    .bottom-bant {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background-color: rgba(33, 47, 50, 0.6);
      text-align: right;
    }
  }

  .next-prev-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 64px;
    height: calc(100% - 50px);
    color: white;
    opacity: 0;
    z-index: -1;
    transition: all ease 0.3s;
    cursor: pointer;
  }

  .next {
    right: 0;
    height: calc(100% - 50px);
  }

  .prev {
    left: 0;
    height: calc(100% - 50px);
  }

  &:hover {
    .next,
    .prev {
      opacity: 1;
      z-index: 1;
    }
  }

  .slick-dots {
    position: static !important;
    // bottom: 23%;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .slick-dots li button:before {
    color: rgba($color: white, $alpha: 0.1);
    opacity: 1;
  }
}
