.tvguide-container {
    width: 100%;
    color: black;
}

.arrow-wrapper {
    display: flex;
    width: 100%;
    height: 3.12vw;
    justify-content: flex-end;
    align-items: center;

    .arrow-box {
        margin-right: 50px;

        span {
            cursor: pointer;

            &.left {
                transform: rotate(180deg);
                margin-right: 20px;
            }
        }
    }
}

.tvguide-wrapper {
    max-width: 61vw;
    max-height: calc(100vh - 280px);
    z-index: 20;
    cursor: grab;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: scroll;
    padding-bottom: 10px;

    @include responsive(1400px) {
        max-height: calc(100vh - 230px);
    }

    &::-webkit-scrollbar {
        height: 8px;
        width: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #fff;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgb(209, 209, 209);
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

.infinite-scroll-component__outerdiv {
    width: 100%;
}