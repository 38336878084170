@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

$softBlue: #49bed8;

.channel-list-item {
  display: flex;
  flex-direction: row;
  width: 300px;
  max-width: 350px;
  height: 88px;
  max-height: 150px;
  align-items: center;
  justify-content: space-around;
  background: #291639;
  cursor: pointer;
  position: relative;
  border: 1px solid #3d4e5a40;
  border-radius: 20px;
  border-color: #000000;
  margin-bottom: 20px;

  &.selected {
    border: 2px solid #3d4e5a40;
    border-radius: 20px;
    border-color: #5A388A;
    margin-bottom: 20px;
  }

  .channel-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    @include responsive(1400px) {
      padding: 0.5vw 2.5vw 0px 2.5vw;
    }

    .logo {
      height: auto;
      max-width: 100%;
      max-height: 57px;
      min-width: 1vw;
      min-height: 1vw;
    }
  }

  .channel-info {
    position: relative;
    text-align: center;
    //width: 50%;
    //min-width: 8vw;

    @include responsive(1400px) {
      padding: 5px;
    }

    .channel-name {
      font-family: 'Open Sans', sans-serif;
      color: white;
      font-size: 0.8vw;
      //min-width: 4vw;
      //width: 100%;
      letter-spacing: -0.32px;
      opacity: 1;
      width: 200px;
      white-space: nowrap;
      word-wrap: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .program-name {
      font-family: 'Open Sans', sans-serif;
      word-wrap: break-word;
      width: 200px;
      color: #db9646;
      font-size: 0.7vw;
      opacity: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .icons {
    display: flex;
    width: 27%;
    padding: 10px;

    @include responsive(1400px) {
      padding: 5px;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #112134 0% 0% no-repeat padding-box;
      border: 0.5999999642372131px solid #3d4e5a80;
      border-radius: 50%;
      width: 1.5vw;
      height: 1.5vw;
      transition: all ease 0.3s;
      color: white;
      font-size: 12px;

      &:hover {
        color: #0c1927 !important;
        background-color: white;
      }

      &.fill-red {
        color: red;
      }

      &.fill-yellow {
        color: #d5dc36;
      }

      .channel-list-item-icons {
        width: 0.625vw !important;
        height: 0.625vw !important;
      }
    }
  }
}

.channel-play-icon,
.channel-lock-icon {
  display: flex;
  width: 5%;
  font-size: 30px;
  height: 85px;
  align-items: center;
  justify-content: center;
  color: $softBlue;
}

.channel-lock-icon {
  color: gray;
}

.colored {
  background: #ffffff1a 0 0 no-repeat padding-box;
}
