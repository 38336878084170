/*
  Player Skin Designer for Video.js
  http://videojs.com

  To customize the player skin edit 
  the CSS below. Click "details" 
  below to add comments or questions.
  This file uses some SCSS. Learn more  
  at http://sass-lang.com/guide)

  This designer can be linked to at:
  https://codepen.io/heff/pen/EarCt/left/?editors=010
*/

// The following are SCSS variables to automate some of the values.
// But don't feel limited by them. Change/replace whatever you want.
// The color of icons, text, and the big play button border.
// Try changing to #0f0
$primary-foreground-color: #cacaca; // #fff default
$secondary-foreground-color: #cfafff;
// The default color of control backgrounds is mostly black but with a little
// bit of blue so it can still be seen on all-black video frames, which are common.
// Try changing to #900
$primary-background-color: #262626; // #2B333F default

// Try changing to true
$center-big-play-button: true; // true default
$big-play-button: false; // hide big play button
$controls-margin: 2em;
// Make a slightly lighter version of the main background
// for the slider background.
$slider-bg-color: #ffffff33;
$progress-position: -2.5em;

@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.video-js.vjs-tt-player {
  /* The base font size controls the size of everything, not just text.
     All dimensions use em-based sizes so that the scale along with the font size.
     Try increasing it to 15px and see what happens. */
  font-size: 12px !important;
  /* The main font color changes the ICON COLORS as well as the text */
  color: $primary-foreground-color !important;
  outline: none !important;
}

.vod-player {
  width: 100% !important;
  height: 100% !important;
  padding-top: 0 !important;
}

/* The "Big Play Button" is the play button that shows before the video plays.
   To center it set the align values to center and middle. The typical location
   of the button is the center, but there is trend towards moving it to a corner
   where it gets out of the way of valuable content in the poster image.*/
.vjs-tt-player {
  .vjs-big-play-button {
    display: none !important;
  }
}

.vjs-default-skin .vjs-big-play-button {
  /* The font size is what makes the big play button...big. 
     All width/height values use ems, which are a multiple of the font size.
     If the .vjs-tt-player font-size is 10px, then 3em equals 30px.*/
  display: none !important;
  font-size: 4em !important;
  /* We're using SCSS vars here because the values are used in multiple places.
     Now that font size is set, the following em values will be a multiple of the
     new font size. If the font-size is 3em (30px), then setting any of
     the following values to 3em would equal 30px. 3 * font-size. */
  $big-play-width: 1.5em !important;
  /* 1.5em = 45px default */
  $big-play-height: 1.5em !important;
  line-height: $big-play-height !important;
  height: $big-play-height !important;
  width: $big-play-width !important;
  /* 0.06666em = 2px default */
  border: 0.06666em solid $secondary-foreground-color !important;
  /* 0.3em = 9px default */
  border-radius: 50% !important;

  @if $big-play-button {
    display: block !important;
  } @else {
    display: none !important;
  }

  @if $center-big-play-button {
    /* Align center */
    left: 50% !important;
    top: 40% !important;
    margin-left: -($big-play-width / 2) !important;
    margin-top: -($big-play-height / 2) !important;
  } @else {
    /* Align top left. 0.5em = 15px default */
    left: 0.5em;
    top: 0.5em;
  }
}

.vjs-tt-player .vjs-play-control,
.vjs-tt-player .vjs-remaining-time,
.vjs-tt-player .vjs-volume-menu-button {
  border-right: 1px solid #323232 !important;
}

.vjs-tt-player .vjs-volume-menu-button {
  .vjs-menu-content {
    &:before {
      content: "" !important;
      display: inline-block !important;
      vertical-align: middle !important;
      height: 100% !important;
    }

    .vjs-volume-bar {
      display: inline-block !important;
      vertical-align: middle !important;
    }
  }
}

/* The default color of control backgrounds is mostly black but with a little
   bit of blue so it can still be seen on all-black video frames, which are common. */

.vjs-tt-player .vjs-control {
  display: none !important;

  &:before {
    font-family: nf-icon !important;
  }

  &.vjs-play-control {
    &:before {
      content: "\e646" !important;
    }

    &.vjs-playing {
      &:before {
        content: "\e645" !important;
      }
    }
  }

  &.vjs-fullscreen-control {
    // &:before {
    //     content: '\e642' !important;
    // }
    .vjs-icon-placeholder {
    }
  }

  &.vjs-volume-menu-button {
    &:before {
      content: "\e630" !important;
    }
  }

  &.vjs-captions-button {
    &:before {
      content: "\e650" !important;
    }
  }
}

.vjs-tt-player .vjs-big-play-button,
.vjs-tt-player .vjs-menu-button .vjs-menu-content {
  /* IE8 - has no alpha support */
  background-color: $primary-background-color !important;
  /* Opacity: 1.0 = 100%, 0.0 = 0% */
  background-color: rgba($primary-background-color, 0.9) !important;
}

.vjs-tt-player .vjs-control-bar {
  z-index: 14 !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.76) 76%
  ) !important;
  width: auto !important;
  height: auto !important;
  // flex-direction: column !important;
  flex-wrap: wrap;
  align-items: center !important;
  justify-content: center !important;
  // left: $controls-margin * 2 !important;
  // right: $controls-margin * 2 !important;
  // bottom: $controls-margin !important;
  padding: 2em 8em;

  .vjs-menu {
    z-index: 2 !important;
    height: 100% !important;
  }
}

.vjs-tt-player .vjs-current-time {
  display: block;
  position: absolute !important;
  right: 0 !important;
  top: $progress-position !important;
}

/* Slider - used for Volume bar and Progress bar */

.vjs-tt-player .vjs-slider {
  background-color: $slider-bg-color !important;
  // border-radius: 1em !important;
  margin: 0 !important;
}

.vjs-tt-player .vjs-remaining-time {
  flex: 1 !important;
  text-align: left !important;
}

/* The slider bar color is used for the progress bar and the volume bar
   (the first two can be removed after a fix that's coming) */

.vjs-tt-player .vjs-volume-level,
.vjs-tt-player .vjs-play-progress,
.vjs-tt-player .vjs-slider-bar {
  background: $primary-foreground-color !important;
  // border-radius: 1em !important;
}

.vjs-tt-player .vjs-play-progress {
  color: $secondary-foreground-color !important;
  background: $secondary-foreground-color !important;
  // font-size: 1.3em !important;

  &:before {
    // transition: width .1s ease-out, height .1s ease-out;
    content: "" !important;
    top: -0.4em !important;
    border: 0.4em solid white !important;
    background: $secondary-foreground-color !important;
    // background: radial-gradient(#b7090b 33%, #830607) !important;
    width: 1.5em !important;
    height: 1.5em !important;
    border-radius: 50% !important;
    box-shadow: #000 0 0 2px !important;
  }

  &:hover {
    &:before {
      // width: 1.1em !important;
      // height: 1.1em !important;
      border: 0.5em solid white !important;
    }
  }

  .vjs-time-tooltip {
    background: white !important;
    font: normal normal 600 1em/1.3em Segoe UI !important;
    color: #767676 !important;
    background-repeat: unset !important;
    top: -0.65em !important;
    border: solid transparent !important;
    border-radius: 1em !important;
    position: absolute !important;
    right: -6em !important;
    width: 5em;
    padding: 0;
  }
}

.vjs-tt-player .vjs-progress-control {
  display: flex !important;
  width: 100% !important;
  height: auto !important;
  // padding: 0 4em 0 0.4em !important;
  justify-content: center !important;
  // top: $progress-position + 0.2em !important;
  // border-radius: 1em !important;
  // transition: top 150ms linear, opacity 150ms linear, transform 150ms linear, -webkit-transform 150ms linear, -moz-transform 150ms linear, -o-transform 150ms linear !important;
  z-index: 0 !important;
  opacity: 1 !important;
  margin-bottom: 1.2vw;

  &:hover {
    .vjs-progress-holder {
      font-size: inherit !important;
    }
  }

  .vjs-mouse-display {
    background: white !important;
    font: normal normal 600 14px/19px Segoe UI !important;
    color: #767676 !important;
    // display: none !important;

    &:before {
      top: 100% !important;
      border: solid transparent !important;
      content: " " !important;
      height: 0 !important;
      width: 0 !important;
      position: absolute !important;
      border-top-color: #262626 !important;
      border-width: 0.8em !important;
      right: 25% !important;
      margin-left: -0.8em !important;
      display: none !important;
    }
  }
}

.vjs-tt-player .vjs-time-tooltip {
  // background: white !important;
  font: normal normal 600 1em/1.3em Segoe UI !important;
  color: #767676 !important;
  // border-radius: 1em !important;
  // background-repeat: unset !important;

  &:before {
    top: 100% !important;
    border: solid transparent !important;
    content: " " !important;
    // height: 0 !important;
    // width: 0 !important;
    position: absolute !important;
    // border-top-color: #262626 !important;
    // border-width: .8em !important;
    right: 25px !important;
    // margin-left: -.8em !important;
    // display: none !important;
  }
}

.vjs-tt-player .vjs-play-progress,
.vjs-tt-player .vjs-load-progress {
  height: 8px !important;
  // transition: all ease 0.3s;
}

.vjs-tt-player .vjs-progress-holder {
  height: 8px !important;

  &:hover {
    .vjs-play-progress {
      background-color: white !important;
    }
  }
}

/* The main progress bar also has a bar that shows how much has been loaded. */

.vjs-tt-player .vjs-load-progress {
  /* For IE8 we'll lighten the color */
  background: lighten($slider-bg-color, 5%) !important;
  /* Otherwise we'll rely on stacked opacities */
  background: rgba($slider-bg-color, 0.5) !important;
  //box-shadow: 0 0.2em 0.1em 0 #000;
  // border-radius: 1em !important;
  height: 8px !important;
}

/* The load progress bar also has internal divs that represent
   smaller disconnected loaded time ranges */

// .vjs-tt-player .vjs-load-progress div {
//     /* For IE8 we'll lighten the color */
//     background: lighten($slider-bg-color, 5%) !important;
//     /* Otherwise we'll rely on stacked opacities */
//     background: rgba($slider-bg-color, 0.75) !important;
//     border-radius: 1em !important;
//     height: 0.9em !important;
// }

// .vjs-loading-spinner {
//     border: none !important;
//     opacity: 0 !important;
//     visibility: hidden !important;
//     animation: vjs-spinner-fade-out 2s linear 1 !important;
//     animation-delay: 2s !important;

//     &:before,
//     &:after {
//         border: none !important;
//     }

//     &:after {
//         background-image: url(https://assets.nflxext.com/en_us/pages/wiplayer/site-spinner.png) !important;
//         background-repeat: no-repeat !important;
//         background-position-x: 50% !important;
//         background-position-y: 50% !important;
//         -moz-background-size: 100% !important;
//         -o-background-size: 100% !important;
//         background-size: 100% !important;
//     }
// }

// .vjs-seeking .vjs-loading-spinner:after,
// .vjs-waiting .vjs-loading-spinner:after {
//     animation: vjs-spinner-spin 1.1s linear infinite, vjs-spinner-fade 1.1s linear 1 !important;
//     animation-delay: 2s !important;
// }

// .vjs-seeking .vjs-loading-spinner,
// .vjs-waiting .vjs-loading-spinner {
//     opacity: 1 !important;
//     visibility: visible !important;
//     animation: vjs-spinner-fade-in 2s linear 1 !important;
//     animation-delay: 2s !important;
// }

// @keyframes vjs-spinner-fade-in {
//     0% {
//         opacity: 0 !important;
//         visibility: visible !important;
//     }

//     100% {
//         opacity: 1 !important;
//         visibility: visible !important;
//     }
// }

// @keyframes vjs-spinner-fade-out {
//     0% {
//         opacity: 1 !important;
//         visibility: visible !important;
//     }

//     100% {
//         opacity: 0 !important;
//         visibility: visible !important;
//     }
// }

.player-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .vod-info-box {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .vod-title {
        font-family: Segoe UI;
        font-size: 2em;
        letter-spacing: -0.57px;
        color: #ffffff;
        margin-bottom: 0.2em;
      }

      .vod-info {
        font: Segoe UI;
        font-size: 1.5em;
        letter-spacing: -0.48px;
        color: #ffffff;
      }
    }

    .control-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 3em;
      color: $secondary-foreground-color;

      .rc-slider-wrapper {
        display: flex;
        align-items: center;
        position: relative;

        &:hover {
          .rc-slider {
            opacity: 1;
          }
        }

        .rc-slider {
          width: 8.6vw;
          opacity: 0;
          transition: all ease 0.3s;

          .rc-slider-track {
            background-color: white !important;
            border-radius: 5px !important;
          }

          .rc-slider-rail {
            background-color: #ffffff33 !important;
            border-radius: 5px !important;
          }

          .rc-slider-handle {
            border: none;
            background-color: white !important;
            margin-top: -5px;
          }
        }
      }

      .control-button {
        display: flex;
        position: relative;
        align-items: center;
        margin-left: 0.7vw;
        padding: 5px;
        font-size: 1.6vw;

        &.selected,
        &.button-white {
          color: white;
        }

        &.hide {
          display: none;
        }

        &.button-yellow {
          border-radius: 50%;
          background-color: #d5dc36;
        }

        &.button-red {
          border-radius: 50%;
          background-color: #ff3b30;
        }

        &.button-blue {
          border-radius: 50%;
          background-color: #00deff;
        }

        &.back-live {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.2vw;
          height: 1.57vw;
          background: #f71100;
          color: white;
          border-radius: 20px;
          margin-left: 0;

          span {
            margin-left: 0.52vw;
            font-family: SFUIText;
            font-size: 0.63vw;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
          }
        }

        &.playback-rate-button {
          justify-content: center;
          align-items: center;
          font-family: SFUITextBold;
          font-size: 1vw;
          background-color: white;
          color: #001b1e;
          width: 2.4vw;
          height: 1.6vw;
          padding: 0;
        }

        .record-text {
          position: absolute;
          top: -100px;
          left: calc(-50% - 30px);
          color: #ffffff7f;
          font-family: SFUITextBold;
          font-size: 1.25vw;
          white-space: nowrap;
        }
      }
    }
  }
}

.rc-slider-rail {
  background-color: #ffffff33 !important;
  border-radius: 0 !important;
}

.rc-slider-track {
  background-color: $secondary-foreground-color !important;
  border-radius: 0 !important;
}

.rc-slider-handle {
  background-color: $secondary-foreground-color !important;
  border: 4px solid white !important;
}

.play-button-box {
  display: flex;
  z-index: 1;
  margin-right: 2em;

  .player-button {
    color: white !important;
    font-size: 3em !important;

    &.centered {
      position: fixed;
      display: flex;
      top: calc(50% - 0.5em);
      left: calc(50% - 0.5em);
    }

    &.exit {
      position: fixed;
      display: flex;
      top: 1em;
      left: 1em;
    }
  }
}

.items-wrapper {
  position: relative !important;
  display: flex;
  justify-content: flex-end;
  // bottom: 12em !important;
  // left: 0;
  // right: 0;
  width: 100%;
  // background-color: black;
  padding: 1em 0;

  .box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 140px;
    padding: 0 10px;

    .player-option-item {
      position: relative;
      font: normal normal normal 17px/22px Segoe UI;
      letter-spacing: -0.01px;
      color: $secondary-foreground-color;
      display: flex;
      align-items: center;
      cursor: pointer !important;

      label {
        cursor: pointer;
      }

      .player-option-label {
        position: relative;
        font-family: SFUIText;
        font-size: 1vw;
        line-height: 42px;
        color: #587086;
        display: flex;
        align-items: center;
        cursor: pointer !important;

        &.selected {
          color: #00deff;
        }

        &.title {
          font-family: SFUITextSemiBold;
          color: white;
        }
      }

      .selected-player-option {
        visibility: hidden;
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        background-color: white;
        margin-right: 5px;
      }

      .selected-player-option.visible {
        visibility: visible;
      }
    }
  }
}

.box-wrapper {
  display: flex;
  justify-content: center;
  width: 300px;
}

.track-seperator {
  width: 1px;
  height: 100%;
  background-color: #767676;
}

.vjs-tt-player .vjs-slider:focus {
  box-shadow: none !important;
  text-shadow: none !important;
}

.play-button-container-centered {
  position: absolute;
  display: flex;
  top: calc(50% - 1.5em);
  left: calc(50% - 1.5em);

  .play-button {
    color: white !important;
    font-size: 3em !important;
  }
}

.exit-button-container {
  position: absolute;
  display: flex;
  top: 2em;
  left: 2em;
  z-index: 11;

  // transition: all ease 0.3s;
  .exit-button {
    font-size: 1.6vw;
    color: white !important;
  }
}

.advert-container {
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //background-color: rgba($color: #000000, $alpha: 0.68);
  z-index: 10;
}

.clickable-screen-container {
  position: absolute;
  display: flex;
  top: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba($color: #000000, $alpha: 0.68);
  z-index: 10;
}

.share-button-container {
  position: absolute;
  display: flex;
  top: 2em;
  right: 2em;
  z-index: 11;
}

@mixin transition($string: $transition--default) {
  -webkit-transition: $string;
  -moz-transition: $string;
  -ms-transition: $string;
  -o-transition: $string;
  transition: $string;
}

.vjs-has-started.vjs-user-inactive.vjs-playing {
  .channel-list-container .channels-wrapper .text-row.show,
  .prev-program-list-container
    .program-slider-wrapper
    .program-slider
    .show-program-arrow,
  .exit-button-container,
  .share-button-container,
  .prev-program-list-container .program-slider-wrapper .program-slider,
  .prev-program-list-container .program-slider-wrapper .show-program-arrow,
  .next-program-list-container .next-program-list-wrapper,
  .play-button-container-centered {
    // Remain visible for screen reader and keyboard users
    visibility: visible;
    opacity: 0;

    $trans: visibility 1s, opacity 1s;
    @include transition($trans);
  }

  .player-gradient {
    z-index: -1 !important;
    opacity: 0 !important;
  }

  .safari-smpte-box {
    bottom: 0;
  }
}

// LIVE PLAYER CSS
.live-player:not(.vjs-fullscreen) {
  .vjs-load-progress,
  .vjs-progress-holder,
  .vjs-play-progress {
    height: 4px !important;
  }

  .vjs-control-bar {
    padding: 3em 4em;

    .vjs-progress-control {
      width: 100% !important;
      left: 0;
      font-size: 0.45em;
      margin: 0;

      .vjs-play-progress {
        .vjs-time-tooltip {
          background: white !important;
          font: normal normal 600 13px/15px Segoe UI !important;
          color: #767676 !important;
          background-repeat: unset !important;
          top: -0.65em !important;
          border: solid transparent !important;
          border-radius: 1em !important;
          position: absolute !important;
          right: -6em !important;
          width: 5em;
          padding: 0;
        }
      }
    }
  }

  .player-row {
    .wrapper {
      justify-content: flex-end;
    }
  }
}

.vjs-tt-player.video-js.live-player .vjs-control-bar {
  display: block !important;
}

.live-player .vjs-control-bar .vjs-progress-control {
  display: none !important;
}

.program-player .vjs-control-bar .vjs-progress-control {
  display: flex !important;
}

.live-info-box {
  display: flex;
  justify-content: space-around;

  .logo {
    display: flex;
    align-items: center;
    width: 5em;

    img {
      width: 100%;
      height: auto;
    }
  }

  .seperator {
    width: 1px;
    height: 100%;
    background-color: #767676;
    margin: 0 10px;
  }

  .live-info {
    display: flex;
    flex-direction: column;

    .channel-name {
      letter-spacing: -0.57px;
      color: #a1a1a1;
    }

    .program-info {
      display: flex;
      align-items: center;
      font: normal normal 300 20px/27px Segoe UI;
      letter-spacing: -0.48px;
      color: #ffffff;
    }
  }
}

.durations-box {
  display: flex;
  margin-left: 1.2vw;

  .durations {
    font-family: SFUIText;
    font-size: 1.2vw;
    color: white;
  }
}

// PLAYER CHANNEL LIST
.channel-list-container {
  position: absolute;
  width: 30%;
  top: 0;
  right: 0;
  z-index: 13;
  height: 100%;

  @include responsive(1400px) {
    width: 40%;
  }

  .channels-wrapper {
    z-index: 10;
    width: 100%;
    height: 100%;

    &.show {
      z-index: 100;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
    }

    .channels-box {
      height: 100%;
      padding: 53px 44px 0 31px;
      width: 80%;
      margin-left: auto;

      &.show {
        background-color: rgba($color: #000000, $alpha: 0.28);
      }

      .channel-list-box {
        position: relative;
        height: 100%;
        transition: all ease 0.3s;
        visibility: hidden;
        display: none;
        opacity: 0;

        .close-list-button {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 3em;
          cursor: pointer;
        }

        &.show {
          visibility: visible;
          display: block;
          opacity: 1;
        }
      }

      .text-row {
        display: none;
        align-items: center;
        justify-content: flex-end;
        font-family: SFUIText;
        font-size: 1.25vw;
        font-weight: bold;
        cursor: pointer;
        min-width: 200px;
        margin-bottom: 40.5px;

        &.show {
          display: flex;
        }

        &.opened {
          justify-content: space-between;
          opacity: 1 !important;

          .arrow {
            transform: rotate(180deg);
          }
        }

        span {
          display: flex;
          margin-right: 5px;
        }

        label {
          color: #ffffff;
          transition: all ease 0.2s;
          cursor: pointer;
        }

        .arrow {
          font-size: 0.78vw;
          margin-left: 20px;
        }
      }

      .filter-box {
        width: 100%;
        font-family: SFUIText;
        font-size: 14px;
        color: white;
        margin-bottom: 37.5px;

        .tv-filter {
          display: flex;
          justify-content: space-between;
          border: 2px solid #ffffff;
          border-radius: 10px;
          padding: 15px 30px;
          cursor: pointer;
          height: 55px;

          &.selected {
            border-color: #00deff;

            .label-text {
              color: #00deff;
            }

            .filter-icon {
              color: #00deff;
            }
          }

          .label-text {
            display: flex;
            align-items: center;
          }

          .filter-icon {
            display: flex;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
            color: white;
          }
        }

        .tv-search {
          display: flex;
          justify-content: space-between;
          border: 2px solid #ffffff;
          border-radius: 10px;
          padding: 15px 30px;
          cursor: pointer;
          margin-bottom: 20px;
          height: 55px;

          #tv-search {
            padding: 0;
            border: none;
            border-radius: 0;
            height: auto;
            font-family: SFUIText;
            font-size: 14px;
            width: -webkit-fill-available;

            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: white;
              opacity: 1;
              /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: white;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: white;
            }
          }
        }
      }
    }
  }
}

// PREV PROGRAM LIST STYLING
.prev-program-list-container {
  position: absolute;
  top: 0;
  left: -100vw;

  .program-slider-wrapper {
    position: relative;
    align-items: center;
    right: 0;
    width: 100vw;
    height: 100vh;
    transition: all ease 0.4s;
    z-index: 10;

    &.show {
      right: -100vw;
      background: transparent
        linear-gradient(180deg, #00000000 0%, #000000b3 11%, #000000 100%) 0% 0%
        no-repeat padding-box;
    }

    .program-slider {
      display: block;
      position: relative;
      width: 95vw;
      right: -8vw;

      &.showing {
        right: 0;
        opacity: 1 !important;

        .show-program-arrow {
          opacity: 1 !important;
        }
      }
    }

    .show-program-arrow {
      display: flex;
      position: relative;
      align-items: center;
      font: normal normal normal 26px/35px Segoe UI;
      letter-spacing: 0px;
      color: #ffffff;
      transition: all ease 0.2s;
      cursor: pointer;
      width: 250px;
      .arrow-wrapper {
        display: flex;
        align-items: center;

        .arrow {
          display: flex;
          position: relative;
          left: -200px;
          transition: all ease 0.2s;
          font-size: 20px;
        }

        label {
          opacity: 0;
          transition: all ease 0.2s;
          cursor: pointer;
        }

        &:hover {
          label {
            opacity: 1;
          }

          .arrow {
            left: 0;
          }
        }
      }
    }
  }
}

// NEXT PROGRAM LIST STYLING
.next-program-list-container {
  position: absolute;
  top: 100vh;
  left: 0;
  // z-index: 10;
  width: 100%;

  .next-program-list-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    top: -1vh;
    height: 95vh;
    transition: all ease 0.4s;
    z-index: 15;

    &.show {
      top: -95vh;
      background: transparent
        linear-gradient(180deg, #00000000 0%, #000000b3 11%, #000000 100%) 0% 0%
        no-repeat padding-box;
      opacity: 1 !important;
    }

    .show-list-arrow {
      position: absolute;
      // top: -70px;
      width: 15vw;
      left: calc(50% - 7.5vw);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font: normal normal normal 26px/35px Segoe UI;
      letter-spacing: 0px;
      color: #ffffff;
      transition: all ease 0.2s;
      cursor: pointer;

      label {
        visibility: hidden;
        opacity: 0;
        transition: all ease 0.2s;
        cursor: pointer;
      }

      .arrow-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;

        .arrow {
          position: relative;
          top: 35px;
          transition: all ease 0.2s;
          font-size: 20px;
        }

        label {
          font-size: 20px;
        }
      }

      &:hover {
        label {
          visibility: visible;
          left: 0;
          opacity: 1;
        }

        .arrow {
          top: 0px;
        }
      }
    }

    .next-program-list {
      display: flex;
      flex-direction: column;
      width: 50%;

      span {
        font: normal normal normal 26px/22px Segoe UI;
        letter-spacing: 0px;
        color: #ffffff;
        margin-bottom: 15px;
      }

      .next-programs {
        // margin-top: 20vh;
        overflow-y: scroll;
        height: -webkit-fill-available;
        scrollbar-color: white transparent;

        &::-webkit-scrollbar {
          width: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #fff;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: rgb(209, 209, 209);
        }
      }
    }
  }
}

.vjs_video_5224-dimensions {
  width: 0 !important;
  height: 0 !important;
}

.tv-player-error {
  width: 100%;
  height: 300px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

// LIVE PLAYER BAR STYLES
.live-player-bar {
  z-index: 100;
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .rc-slider-wrapper {
    position: relative;
    width: 100%;
    cursor: pointer;

    .rc-slider {
      transition: top 150ms linear, opacity 150ms linear, transform 150ms linear,
        -webkit-transform 150ms linear, -moz-transform 150ms linear,
        -o-transform 150ms linear !important;
      opacity: 1 !important;
      font-size: 1em !important;
      padding: 0;
      height: unset;
      border-radius: 0 !important;

      .rc-slider-step,
      .rc-slider-track,
      .rc-slider-rail {
        height: 4px;
      }

      .rc-slider-handle {
        width: 14px;
        height: 14px;
        border: 4px solid white !important;
        margin-top: -5px;
      }
    }
  }
}

.live-player.vjs-fullscreen {
  .vjs-tt-player .vjs-play-progress,
  .vjs-tt-player .vjs-load-progress {
    height: 8px !important;
    // transition: all ease 0.3s;
  }

  .live-player-bar {
    z-index: 10;
    position: relative;
    display: flex;

    .rc-slider-wrapper {
      width: 100%;

      .rc-slider {
        transition: top 150ms linear, opacity 150ms linear,
          transform 150ms linear, -webkit-transform 150ms linear,
          -moz-transform 150ms linear, -o-transform 150ms linear !important;
        opacity: 1 !important;
        font-size: 1em !important;
        border-radius: 0 !important;

        .rc-slider-step,
        .rc-slider-track,
        .rc-slider-rail {
          height: 7px;
        }

        .rc-slider-handle {
          width: 14px;
          height: 14px;
          border: 4px solid white !important;
          margin-top: -3px;
        }
      }
    }
  }

  // .vjs-play-progress {
  //     .vjs-time-tooltip {
  //         background: white !important;
  //         font: normal normal 600 15px/18px Segoe UI !important;
  //         color: #767676 !important;
  //         background-repeat: unset !important;
  //         top: -0.65em !important;
  //         border: solid transparent !important;
  //         border-radius: 1em !important;
  //         position: absolute !important;
  //         right: -6em !important;
  //         width: 5em;
  //         padding: 0;
  //     }
  // }
}

.ad-box {
  display: none;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 50%;
  background: transparent;
  position: absolute;
  top: 25%;
  left: 10%;
}

.advertisement-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;

  &.show {
    z-index: 10;
    // background: rgba(0, 0, 0, 0.5);
  }

  &.hide {
    background: none;
    z-index: -5;
    visibility: hidden;
  }
}

.next-episode-container {
  position: absolute;
  bottom: 6.5vw;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 11;

  .next-episode-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 4vw;

    .next-episode-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 200px;
      color: white;

      .seperator {
        width: 1px;
        height: 100px;
        background-color: #797979;
      }
    }

    .episode-info-box {
      display: flex;
      flex-direction: column;
      // width: 40%;

      .episode-title {
        font-family: SFUIText;
        line-height: 2.2vw;
        font-size: 1.25vw;
        color: white;
      }

      .episode-info {
        font-family: SFUIText;
        font-size: 1vw;
        color: white;
      }
    }
  }
}

.video-player {
  position: relative;
}

.player-gradient {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  transition: all ease 0.3s;
}

.record-options-container,
.limit-warning-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

//smpte subtitle style
.vjs-text-track-display-ttml {
  text-align: center;
  //bottom: 300px !important;

  img {
    width: unset !important;
  }
}

.safari-smpte-box {
  position: absolute;
  bottom: 100px;
  width: 0;
}
