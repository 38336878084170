body {
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}
.download-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #1B1629 0% 0% no-repeat padding-box;
  margin: auto;
  margin-top: 1.56vw;
  width: 70%;
  .text {
      font-family: 'Open Sans', sans-serif;
      font-size: 1.82vw;
      line-height: 5vw;
      color: white;
  }
  .mini-text {
      font-family: 'Open Sans', sans-serif;
      font-size: 0.8vw;
      line-height: 2vw;
      color: white;
  }
  .settings-icon {
    display: flex;
    align-items: center;
    margin-left: 1.56vw;
    margin-bottom: 1.56vw;
    cursor: pointer;
    font-size: 1.25vw;

    label {
      font-family: 'Open Sans', sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
      margin-left: 2.9vw;
      cursor: pointer;
    }

    .icon-settings {
      display: flex;
      margin-left: 30px;
      color: #ffffff;
      font-family: 'Open Sans', sans-serif;
      letter-spacing: 0px;
      opacity: 1;
    }
  }
  .download-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 700px;
    height: 4.7vw;
    background-color: #2f2559;
    border: 1px solid #3d4e5a40;
    opacity: 1;
    padding: 0 2.6vw;
    margin-bottom: 1.56vw;
    cursor: pointer;
      .download-info{
          margin-top: 80px;
          color: white;
      }
      .download-name{
          font-family: 'Open Sans', sans-serif;
          font-size: 0.8vw;
          line-height: 2vw;
          color: white;
      }
      .download-desc{
          font-family: 'Open Sans', sans-serif;
          font-size: 0.8vw;
          line-height: 2vw;
          color: white;
      }
      .download-button{
          margin-top: 80px;
          width: 160px;
          height: 50;
          background-color: #B783FF;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-family: 'Open Sans', sans-serif;
          font-size: 0.8vw;
          line-height: 2vw;
          color: black;
          cursor: pointer;
      }
      .download-icon{
          width: 80%;
          margin-top: 40px;
      }
  }
  label {
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    margin-left: 2.9vw;
    cursor: pointer;
  }
  .download-info{
      display: flex;
      flex-direction:row;
      align-items: center;
  }
}