.profile-settings-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .profile-settings-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;
        z-index: 10;

        .current-avatar-wrapper {
            position: relative;
            margin-top: 5vw;
            margin-bottom: 3vw;
            width: 8vw;

            img {
                width: 100%;
                height: auto;
            }

            .edit-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                width: 2.86vw;
                height: 2.86vw;
                border: 2px solid #09121C;
                border-radius: 50%;
                background-color: #00DFFF;
                color: white;
                font-size: 1.45vw;
                cursor: pointer;
            }
        }

        .form-wrapper {
            width: 100%;

            .form-group {
                margin-bottom: 1vw;

                .kid-checkbox-row {
                    display: flex;
                    align-items: center;

                    .kid-checkbox {
                        width: 1.25vw;
                        height: 1.25vw;
                        border: 2px solid #FFFFFF;
                        border-radius: 4px;
                        cursor: pointer;
                        transition: all ease .3s;

                        &.checked {
                            padding: 3px;
                            background-color: white;
                            content: url("../../assets/icons/svg/kidChecked.svg");
                        }
                    }

                    span {
                        margin-left: 25px;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 1vw;
                        color: white;
                    }
                }

                .alert-text {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 14px;
                    color: red;
                }
            }
        }
    }

    .avatar-wrapper {
        width: 30%;
        z-index: 10;

        .avatar-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 2vw 0;

            .avatar-item {
                display: flex;
                flex-direction: column;
                width: 25%;
                padding: 10px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: auto;
                    border-radius: 15px;
                }

                &.selected {
                    border: 2px solid white;
                    border-radius: 50%;
                }
            }
        }

        .save-button {
            width: 50%;
            margin: auto;
        }
    }
}


.form-buton-group {
    display: flex;

}

.white-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 3.12vw;
    font-family: 'Open Sans', sans-serif;
    font-size: 1vw;
    color: #0C1927;
    cursor: pointer;
    border-radius: 4px;
    transition: all ease .3s;
    border: 2px solid transparent;
}