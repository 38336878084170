@import "../../style/common";

$white: #fff;

.forgot-password-title {
  width: 63.75%;
  @include login-text(Swis721HvBT, 46px, auto, 3%);
  line-height: 46px;
}

.forgot-password-subtitle {
  @include login-text(SFUITextLight, 14px, 26px, 1.6%);
}

.input-phone-number {
  @include login-input(300px, 45px);
  margin-top: 2.5%;
  &::placeholder {
    color: $white;
  }
}

.input-error {
  @include input-error(300px);
}

.submit-button {
  @include login-button(
    SFUITextSemibold,
    $white,
    2.7%,
    #0c1927,
    300px,
    45px,
    45px
  );
}

.done-section {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7%;
  margin-top: 30px;
  .done-message {
    padding: 0 60px;
    @include login-text(SFUITextLight, 22px, 26px, 0%);
    margin-bottom: 3%;
  }
}

@media (width >= 1920px) and (height >= 900px) {
  .forgot-password-title {
    @include login-text(Swis721HvBT, 65px !important, auto, 6% !important);
  }

  .submit-button {
    @include login-button(
      SFUITextBold,
      $white,
      3.6%,
      #0c1927,
      450px !important,
      65px !important,
      65px !important
    );
  }
}

@media (width >= 1920px) {
  .forgot-password-title {
    @include login-text(Swis721HvBT, 65px, auto, 4%);
  }
  .forgot-password-subtitle {
    @include login-text(SFUITextLight, 22px, 26px, 2.7%);
  }

  .input-phone-number {
    @include login-input(450px, 65px);
  }

  .input-error {
    @include input-error(450px);
  }

  .submit-button {
    @include login-button(
      SFUITextBold,
      $white,
      2.7%,
      #0c1927,
      450px !important,
      65px !important,
      65px !important
    );
  }
}

@media (height >= 900px) {
  .forgot-password-title {
    @include login-text(Swis721HvBT, 46px, auto, 5%);
  }

  .input-phone-number {
    margin-top: 3.5%;
  }
}
