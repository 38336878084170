.notification-container {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(180deg, #00000000 0%, #000000B3 11%, #000000 100%) 0% 0% no-repeat padding-box;
    transition: all ease 0.3s;
    opacity: 0;

    @media (max-width: 768px) {
        .notification-box {
            width: 80%;
            height: 30%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #291639;
            padding: 1.6vw 2.6vw;
            border-radius: 8px;
            .close-row {
                width: 100%;
                display: flex;
                justify-content: flex-end;


                .close {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2vw;
                    height: 2vw;
                    background: #FFFFFF4D;
                    border: 1px solid #FFFFFF80;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }

            .text {
                font-family: 'Open Sans', sans-serif;
                font-size: 5vw;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-align: center;
                margin-bottom: 30px;
                margin-top: 30px;
            }

            .button-wrapper {
                display: flex;
                width: 100%;
                margin-top: 20px;

                .button-notification {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 12.24vw;
                    border-radius: 2px;
                    transition: all ease 0.3s;
                    cursor: pointer;

                    &.confirm {
                        background-color: #b783ff;
                        border: 2px solid #b783ff;
                        color: #0D1928;
                    }

                    &.notconfirm {
                        border: 2px solid #FFFFFF;
                        color: white;
                    }

                    span {
                        opacity: 1;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 5vw;
                    }

                    &.hide {
                        display: none;
                    }
                }
            }
        }
    }

    @media (min-width: 768px) {
        .notification-box {
            width: 26vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #291639;
            padding: 1.6vw 2.6vw;
            border-radius: 8px;

            .close-row {
                width: 100%;
                display: flex;
                justify-content: flex-end;


                .close {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2vw;
                    height: 2vw;
                    background: #FFFFFF4D;
                    border: 1px solid #FFFFFF80;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }

            .text {
                font-family: 'Open Sans', sans-serif;
                font-size: 1.2vw;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-align: center;
                margin-bottom: 30px;
                margin-top: 30px;
            }

            .button-wrapper {
                display: flex;
                width: 100%;
                margin-top: 20px;

                .button-notification {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 3.12vw;
                    border-radius: 2px;
                    transition: all ease 0.3s;
                    cursor: pointer;

                    &.confirm {
                        background-color: #b783ff;
                        border: 2px solid #b783ff;
                        color: #0D1928;
                    }

                    &.notconfirm {
                        border: 2px solid #FFFFFF;
                        color: white;
                    }

                    span {
                        opacity: 1;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 1vw;
                    }

                    &.hide {
                        display: none;
                    }
                }
            }

        }
    }
}

.notification-container.open {
    opacity: 1;
}

#passwordModalForm {
    width: 100%;
}