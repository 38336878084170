@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.channel-carousel-item-card {
  padding: 0.78vw;

  .channel-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff33;
    border-radius: 10px;
    transition: all ease 0.3s;
    cursor: pointer;

    &:hover {
      background-color: #ffffff66;
    }

    @include responsive(1400px) {
      // padding: 20px 50px;
    }
  }
  &.search-item {
    width: 20%;
  }

  .title {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.84vw;
    letter-spacing: 0px;
    color: #cccccc;
    padding: 10px 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
