.btn {
    border-radius: 20px;
    padding: 5px 30px;
    background-color: white;
    color: $tivibuSoftBlueColor !important;
    font: normal normal normal 20px/27px 'Open Sans', sans-serif !important;

    &.hide {
        display: none;
    }
}

.btn.hidden {
    visibility: hidden;
}

.btn-gray {
    background-color: gray !important;
    color: white !important;
}

.btn-gray-tr {
    background-color: rgba(255, 255, 255, 0.25);
    transition: all ease 0.3s;
    color: white;

    &:hover {
        background-color: white;
        color: $tivibuSoftBlueColor;
    }
}

.btn-blue {
    background-color: $tivibuSoftBlueColor !important;
    color: white !important;
}