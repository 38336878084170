$genreTextColor: #49bed8;

.genre-box {
  padding: 15px 0;
  cursor: pointer;
  direction: ltr;
  // min-width: 40%;

  .genre-text {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    color: white;
    white-space: nowrap;
    font-size: 1vw;
    white-space: nowrap;
  }

  .selected-genre {
    color: #f079ff;
  }
}
