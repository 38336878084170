.signup-label {
  margin-top: 55px;
  color: #fff;
  width: 300px;
  text-align: start;
  font-size: 14px;
  font-weight: bold;
}
.login-button-container {
  width: 300px;
  height: 45px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.continue-btn {
  display: flex;
  align-items: center;
  gap: 15px;
  //position: absolute;
  //right: 40vw;
  width: 40%;
  height: 100%;
  padding-inline: 15px;
  justify-content: space-between;
  font-size: clamp(16px, 1.25vw, 16px);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: "Open Sans", sans-serif;
  color: #0c1927;
  background-color: #b783ff;
  border-radius: 5px;
  border: 2px solid #b783ff;
  min-width: 80px;
  min-height: 40px;
  &:hover {
    opacity: 0.79;
  }
}

.back-btn {
  //position: absolute;
  z-index: 10;
  //left: 40vw;
  display: flex;
  align-items: center;
  gap: 15px;
  //position: absolute;
  width: 40%;
  height: 100%;
  padding-inline: 15px;
  justify-content: space-between;
  font-size: clamp(16px, 1.25vw, 16px);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: "Open Sans", sans-serif;
  color: #0c1927;
  background-color: #b783ff;
  border-radius: 5px;
  border: 2px solid #b783ff;
  min-width: 80px;
  min-height: 40px;

  &:hover {
    opacity: 0.79;
  }

  svg {
    transform: rotate(180deg);
  }
}
.button-back {
  width: auto;
  height: auto;
  border-radius: 0px 5px 5px 0px;
}
.btn-again {
  font-size: 16px;
}
