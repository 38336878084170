@mixin responsive($query) {
  @media (max-width: + $query) {
    @content;
  }
}

.mainboard-thumbnail-wrapper {
  width: 100%;
  position: absolute;
  // bottom: 0;
  top: 70%;
  left: 0;

  .thumbnail-slider {
    width: 70%;
    margin: auto;

    .slick-slider {
      position: relative;
      left: -20px;
    }

    .thumbnail-item {
      outline: none;
      cursor: pointer;
      padding: 20px;

      .img-wrapper {
        position: relative;

        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
        }

        .mask {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
          border-radius: 10px;
          opacity: 0.7;
          background-color: #000;
          transition: all ease 0.3s;
        }
      }

      &:hover {
        .img-wrapper {
          .mask {
            opacity: 0.3;
          }
        }
      }
    }

    .thumbnail-progress {
      height: 3px;
      position: absolute;
      bottom: 25px;
      display: flex;
      justify-content: center;
      .progress-all {
        width: 95%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);

        .progress {
          height: 100%;
          width: 0;
          background-color: #830db7;
          // transition: all ease 0.3s;

          &.progress-animation {
            animation: progressBar 10s ease-in-out;
            animation-fill-mode: both;
            animation-iteration-count: 1;
            // animation-delay: 2000ms;
          }
        }
      }
    }

    @include responsive(1400px) {
      width: 75%;
    }
  }

  @include responsive(1400px) {
    //bottom: 19%;
  }
}

.slick-current {
  .thumbnail-item {
    cursor: pointer;

    .img-wrapper {
      img {
        box-shadow: 0px 0px 15px 5px #fff;
      }

      .mask {
        display: none !important;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        border-radius: 10px;
        opacity: 0.7;
        background-color: #000;
      }
    }
  }
}

@keyframes progressBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }

  0% {
    width: 0;
  }
}
