@import "../../style/common";

$white: #fff;

.login-page-container {
  width: 100%;
  min-height: 100vh;
  //height: 115vh !important;
  position: relative;
  display: flex;
  justify-content: center;
  background: transparent linear-gradient(180deg, #000000ac 0%, #5a398a 100%) 0%
    0% no-repeat padding-box;
  .go-back-icon {
    position: absolute;
    left: 82px;
    top: 32px;
    cursor: pointer;
    svg {
      width: 33px;
      height: 29px;
    }
  }

  .login-content {
    width: 100%;
    //margin-top: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    .filbox-logo {
      // margin-top: 70px;
      max-width: 200px;
      width: 10vw;
      height: auto;
      min-width: 192px;
    }

    .login-line {
      background-color: $white;
      height: 1px;
      width: 300px;
      margin: 30px 0px 20px 0px;
      opacity: 0.1;
    }

    .article-2 {
      font-family: "Open Sans", sans-serif;
      color: #fff;
      font-size: 13px;
      width: 100%;
      line-height: 16px;
      letter-spacing: 0px;
      font-weight: 400;
    }

    .sign-up-button {
      @include login-button(
        SFUITextBold,
        #d5dc36,
        1.5%,
        #0c1927,
        300px,
        45px,
        45px
      );
    }
  }
}

@media (width >= 1920px) {
  .login-page-container {
    // .login-content {
    //   .filbox-logo {
    //     width: 300px;
    //     height: auto;
    //   }

    //   .login-line {
    //     width: 450px;
    //     margin: 30px 0px;
    //   }

    //   .sign-up-button {
    //     @include login-button(
    //       SFUITextBold,
    //       #d5dc36,
    //       1.5%,
    //       #0c1927,
    //       450px,
    //       65px,
    //       65px
    //     );
    //   }
    // }
  }
}

@media (height >= 900px) {
  .login-page-container {
    .login-content {
      margin-top: 2%;
    }
  }
}
